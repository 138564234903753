import React, {useState} from 'react'
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell'

import {normalizePath} from 'utils/Util'
import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import { WrapForm } from 'components/molecules/Wrap'
import newRowsPager from 'components/organisms/newRowsPager'
import ResourceStatusButton from 'components/atoms/ResourceStatusButton'
import ButtonUpload from 'components/atoms/ButtonUpload'
// import qs from 'query-string'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button'

export default ({match}) => {
  const prefix = normalizePath(match.params[0])
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)

  const itemPager = newRowsPager({
    headers: ['path', 'name/slug', 'status', 'action'],
    onPage: ({offset, limit, updateRows}) => {
      // console.log('offlimit', offset, limit)
      const params = {
        query: {prefix, offset, limit}
      }
      setLoading(true)
      Api.fetchAuth(`/labels`, params).then(r=>r.json()).then(response=>{
        updateRows(response.data, response.total)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row, updateRowById}) => (
      <React.Fragment>
        <TableCell>
          <Link href={`/labels/${row.id}`}>
            {row.path}
            {/* {console.log('label', row)} */}
          </Link>
        </TableCell>
        <TableCell>
          {row.name}<br />
          {row.slug}
        </TableCell>
        {/*<TableCell>{row.name}</TableCell>*/}
        <TableCell><Chip size="small" label={row.status} /></TableCell>
        <TableCell>
          <ResourceStatusButton
            resource_key="labels"
            resource={row}
            updateRowById={updateRowById}
            disabled={true}
          />
        </TableCell>
      </React.Fragment>
    )
  })
  
  return (
    <WrapForm>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            ラベル一覧　<Chip size="small" label={prefix} />
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <ButtonUpload href={`/labels/upload`} />
              </Grid>
              <Grid item>
                <Button href={`/labels/new${prefix}`} variant="contained" color="default"
                  size="small" startIcon={<AddCircleIcon />}>
                  New
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Typography>
      
      {loading && <CircularProgress size={20} />}
      {itemPager.render}
    </WrapForm>
  )
}
