import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
// import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import HelpIcon from '@material-ui/icons/Help'

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import {Wrapper, WrapForm, Section} from 'components/molecules/Wrap'

import Uploader from 'components/molecules/forms/Uploader'

export default (props) => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(false)

  const onDrop = acceptedFiles => {
    setLoading(true)
    const file = acceptedFiles[0]
    const formData = new FormData()
    formData.append("file", file)
    Api.fetchAuth(`/labels/upload`, {
      method: 'POST',
      body: formData
    }).then(r=>r.json()).then(response=>{
      // console.log(response)
      setLoading(false)
      window.location.href = `/labels`
    }).catch(handleApiError)
  }

  return (
    <WrapForm>
      <Typography variant="h6" gutterBottom>
        Upload Labels
      </Typography>
      <Wrapper>
        <Uploader 
          onDrop={onDrop} 
          message={loading ? (<CircularProgress />) : null} />
      </Wrapper>

      {renderInstruction()}
    </WrapForm>
  )
}

const renderInstruction = () => {
  return (
    <Section icon={<HelpIcon />} title="About csv Format">
      <Wrapper>
        <Typography variant="body1">
          csvにはヘッダ行（はじめの1行目）が必須です。（下記サンプル参考）<br/>
          ヘッダ行のとおりであれば順番はこのとおりでなくてもかまいません。
        </Typography>
      </Wrapper>

      <span>※ csvサンプル</span>
      <Paper>
        <div style={{width: '100%', overflowX: 'scroll'}}>
          <pre>{`
            path,name,image_url
            /area/tokyo,東京,
            /area/osaka,大阪,
            /area/kanagawa,神奈川,
            /area/fukuoka,福岡,
            /area/aichi,愛知,
            /area/aichi/nagoya,名古屋,https://www.nagoya-info.jp/en/see/assets_c/2018/04/shisetsu_nagoyaj_1-thumb-600xauto-39991.jpg
            /area/aomori,青森,
            /area/aomori/hachinohe,八戸,
            /area/gunma,群馬,
            /area/tokyo/shinjuku,新宿,https://upload.wikimedia.org/wikipedia/commons/0/00/Kabukicho-Shinjuku-Tokyo_2015.jpg
          `}</pre>
        </div>
      </Paper>
    </Section>
  )
}