import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import AssetCard from 'components/molecules/AssetCard'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  clickable: {
    cursor: 'pointer',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& img': {
      maxWidth: 300,
    },
  },
  contentArea: {
    height: '100%',
  },
  block: {
    marginBottom: theme.spacing(2),
  }
}));

export default ({items, onClick}) => {
  const classes = useStyles()

  return (
    <Container className={classes.root} maxWidth="md">
      <Grid container spacing={1}>
        {items.map(item=>(
          <Grid item key={item.id} xs={4} sm={3} md={2}>
            <AssetCard item={item} onClick={onClick} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
