import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  typography: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    '& svg': {
      marginRight: theme.spacing(1),
    }
  },
}))

export default (props) => {
  const {children, icon} = props
  const classes = useStyles()
  const iconComponent = icon && (typeof icon === 'string') ?
    (<Icon>{icon}</Icon>) :
    icon
  return (
    <Typography component="div" className={classes.typography} {...props}>
      {iconComponent} {children}
    </Typography>
  )
}
