import React from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import newTextField from 'components/molecules/forms/newTextField'
import newKeyValueFields from 'components/molecules/forms/newKeyValueFields'
import newNestedKeyValueFields from 'components/molecules/forms/newNestedKeyValueFields'
// import newVariadicField from 'components/molecules/forms/newVariadicField'
import newCodeEditor from 'components/molecules/forms/newCodeEditor'

import HelpTip from 'components/atoms/HelpTip'

export default ({labelMaster, save, subject}) => {
  subject = subject || {}
  const slug = newTextField({default: subject.slug, label: "Slug", fullWidth: true, disabled: !!subject.slug})
  
  const interfaceArgs = newNestedKeyValueFields({
    default: subject.interface,
    label: "インターフェース", keyLabel: "変数", valueLabel: "",
    schema: {
      default: { label: "デフォルト値", type: "string" },
      required: { label: "必須", type: "boolean" },
    }
  })
  const resolver = subject.resolver || {}
  const resolvers = Object.keys(resolver).map(key=>({key, ...resolver[key]}))
  const resolver1Key = newTextField({
    label: "変数名",
    defaultValue: resolvers[0] && resolvers[0].key, fullWidth: true,
  })
  const resolver1ApiPath = newTextField({
    label: "apiエンドポイント",
    defaultValue: resolvers[0] && resolvers[0].api_path, fullWidth: true,
  })
  const resolver1ApiParams = newKeyValueFields({
    label: "apiパラメータ",
    default: resolvers[0] && resolvers[0].api_params,
  })
  const resolver2Key = newTextField({
    label: "変数名",
    defaultValue: resolvers[1] && resolvers[1].key, fullWidth: true,
  })
  const resolver2ApiPath = newTextField({
    label: "apiエンドポイント",
    defaultValue: resolvers[1] && resolvers[1].api_path, fullWidth: true,
  })
  const resolver2ApiParams = newKeyValueFields({
    label: "apiパラメータ",
    default: resolvers[1] && resolvers[1].api_params,
  })
  
  const codeEditor = newCodeEditor({
    defaultValue: subject.body,
    style: {
      fontSize: '1rem',
      width: 400,
    }
  })
  const body = {
    slug: slug.value,
    interface: interfaceArgs.values,
    resolver: [
      [resolver1Key.value, {api_path: resolver1ApiPath.value, api_params: resolver1ApiParams.values}],
      [resolver2Key.value, {api_path: resolver2ApiPath.value, api_params: resolver2ApiParams.values}],
    ].filter(x=>x[0]).reduce((acc,x)=>{
      acc[x[0]] = x[1]
      return acc
    }, {}),
    body: codeEditor.value,
  }

  return (
    <React.Fragment>
      <GeneratedForm handleSave={()=>save(body)} objects={[
        slug,
        interfaceArgs, 

        (<InputLabel>リゾルバ1</InputLabel>),
        resolver1Key, resolver1ApiPath, resolver1ApiParams,
        (<InputLabel>リゾルバ2</InputLabel>),
        resolver2Key, resolver2ApiPath, resolver2ApiParams,

        (<Box display="block"><pre><code>{subject.setting_json}</code></pre></Box>),

        codeEditor
      ]} />
    </React.Fragment>
  )
}