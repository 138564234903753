import queryString from 'query-string'
import AuthToken from './AuthToken'
import Env from './Env'

export default class Api {

  static get enablesLogging() {
    return process.env.REACT_APP_ENABLE_API_LOGGING
  }

  static buildUrl( path ) {
    // const prefix = process.env.REACT_APP_API_PREFIX ?
    //   process.env.REACT_APP_API_PREFIX :
    //   Env.api_prefix
    const prefix = Env.api_prefix
    return prefix + path
  }

  static fetchBase( url, params ) {
    this.requestDebugPrint( url, params )
    const apiParams = Object.keys(params).reduce((acc,key)=>{
      acc[key] = params[key]
      return acc
    }, {})

    return fetch( url, apiParams ).then((response)=>{
      this.responseDebugPrint( response ) 
      if ( !response.ok ) {
        // throw new Error(response)
        throw (response)
      }

      return response
    })
  }

  static fetch( path, params ) {
    params = params || {}
    let url = this.buildUrl( path )
    if ( params.body && !(params.body instanceof FormData) ) {
      params.body = JSON.stringify(params.body)
      params.headers = params.headers || {}
      params.headers['Accept']        = 'application/json'
      params.headers['Content-Type']  = 'application/json'
    }
    if ( params.query ) {
      const qs = queryString.stringify(params.query, {arrayFormat: 'bracket'})
      url = `${url}?${qs}`
      delete params.query
    }
    return this.fetchBase( url, params )
  }

  static fetchAuth( path, params ) {
    if ( ! params ) params = {}
    if ( ! params.headers ) params.headers = {}
    // const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    // params.headers['X-CSRF-Token'] = csrfToken
    // params.credentials = "same-origin"
    const token = AuthToken.token
    params.headers["Authorization"] = `Bearer ${token}`
    return this.fetch( path, params )
  }

  static requestDebugPrint( url, params ) {
    if ( ! this.enablesLogging ) return
    let method  = ''
    let headers = ''
    let bodies  = ''
    if ( params.headers ) {
      headers = Object.keys(params.headers).map((k)=>{
        return `${k}: ${params.headers[k]}`
      }).join("\" -H \"")
      if ( headers ) {
        headers = "-H \"" + headers + "\""
      }
    }
    let jsonBody = null
    if ( params.body && typeof params.body === 'string' ) {
      jsonBody = JSON.parse( params.body )
    }
    if ( params.method !== "GET" && jsonBody ) {
      method = `-X ${params.method}`
      bodies = Object.keys(jsonBody).map((key)=>{
        return `${key}=${jsonBody[key]}`
      }).join("\" -F \"")
      if ( bodies ) {
        bodies = "-F \"" + bodies + "\""
      }
    }

    console.log("fetch.curl", `curl ${method} ${headers} ${bodies} "${url}"`)
  }

  static responseDebugPrint( response ) {
    if ( ! this.enablesLogging ) return
    console.log("fetch.response", response)
  }

  static handleError( error ) {
    console.error( error )
  }
}

const errors = {
  authentication_failed: "メールアドレスかパスワードが間違っています。",
}

export const handleError = ({messenger}) => {
  return (error) => {
    if (error instanceof Error) {
      messenger( error.message )
      return
    }

    error.json().then(response => {
      if ( response.message ) {
        messenger( response.message )
      } else {
        const msg = errors[response.code]
        messenger( msg || "エラーが発生しました。継続する場合は運営にお問い合わせください。" )
      }
    }).catch(errorOfError=>{
      // Error Response is not JSON
      messenger( `${error.status} ${error.statusText}` )
    })
  }
}

export const buildLimitOffsetQuery = ({limit, location_search}) => {
  limit = limit || 60
  const page = queryString.parse(location_search, { ignoreQueryPrefix: true }).page || 1
  const offset = 60 * (page-1)
  return { limit, offset }
}