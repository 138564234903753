import React, {useState, useEffect} from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import Api, { buildLimitOffsetQuery } from 'utils/Api'
import useFlash from 'modules/useFlash'
import {WrapForm, InfoPaper, Wrapper} from 'components/molecules/Wrap'

import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';

import StatusLabel from './parts/StatusLabel'
import ButtonDownload from 'components/atoms/ButtonDownload'

import ResourceStatusButton from 'components/atoms/ResourceStatusButton'
import newRowsPager from 'components/organisms/newRowsPager'

export default (props) => {
  const def_id = props.match.params.def_id
  
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [def, setDef] = useState()

  useEffect(()=>{
    setLoading(true)
    
    const api1 = Api.fetchAuth(`/defs/${def_id}`).then(r=>r.json())

    Promise.all([api1]).then(responses=>{
      setDef(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })

  }, [])


  const itemPager = newRowsPager({
    headers: ['slug', 'タイトル', 'status'],
    onPage: ({offset, limit, updateRows}) => {
      // console.log('offlimit', offset, limit)
      const params = {
        query: {offset, limit}
      }
      setLoading(true)
      Api.fetchAuth(`/defs/${def_id}/resources`, params).then(r=>r.json()).then(response=>{
        updateRows(response.data, response.total)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row, updateRowById}) => (
      <React.Fragment>
        <ListItem {...row} />
        <TableCell>
          <ResourceStatusButton 
            resource_key={`defs/${def.id}/resources`}
            resource={row} 
            updateRowById={updateRowById} />
        </TableCell>
      </React.Fragment>
    )
  })

  const handleDownload = () => {
    Api.fetchAuth(`/defs/${def_id}/resources.csv`, {}).then(r=>r.blob()).then(res=>{
      const uri = URL.createObjectURL(res)
      const link = document.createElement("a")
      link.href = uri
      link.click()
    }).catch(handleApiError)
  }
  
  if (!def) return (<CircularProgress />)
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>リソース {def.slug} 一覧</Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <ButtonDownload onClick={handleDownload} />
              </Grid>
              <Grid item>
                <Button href={`/defs/${def_id}/resources/new`} variant="contained" color="default"
                  size="small" startIcon={<AddCircleIcon />}>
                  New
                </Button>
              </Grid>
              <Grid item>
                <Button href={`/defs/${def_id}/resources/upload`} variant="contained" color="default"
                  size="small" startIcon={<AddCircleIcon />}>
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Typography>
      
      {loading && <CircularProgress size={20} />}
      {itemPager.render}
    </Wrapper>
  )
}

const ListItem = ({definition, ...row}) => (
  <React.Fragment>
    <TableCell>
      <Link href={`/defs/${definition.id}/resources/${row.id}`} color="inherit">
        <Typography variant="body2">{row.slug}</Typography>
      </Link>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{row.title}</Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{row.status}</Typography>
    </TableCell>
  </React.Fragment>
)

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
