import React, {useState} from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';

import Api, { handleError } from '../../utils/Api'
import useFlash from '../../modules/useFlash'
import newCodeEditor from '../molecules/forms/newCodeEditor'

export const TextEditor = ({asset, onSave}) => {
  const [editing, setEditing] = useState(false)
  const codeEditor = newCodeEditor({
    defaultValue: (editing && asset.draft_content) ? asset.draft_content : asset.text_content,
    options: {
      readOnly: editing ? false : 'nocursor',
      mode: asset.content_type,
    },
  })
  const {handleApiError} = useFlash()
  const handleSave = () => {
    console.log('save', codeEditor.value)

    Api.fetchAuth(`/main/draft_files/default`, {
      method: 'PUT',
      body: {
        path: asset.path,
        body: codeEditor.value,
        base_version_id: asset.version.id,
      }
    }).then(r=>r.json()).then(res=>onSave(res)).catch(handleApiError)
  }
  const hasDraft = (asset.kind === 'draft') ? true :
    (!!asset.draft_content)

  return (
    <div>
      <Grid container alignItems="flex-start" justify="flex-end" direction="row">
        <Badge color="secondary" badgeContent={1} invisible={!hasDraft}>
          <FormControlLabel control={
            <Switch color="primary" checked={editing} onChange={()=>setEditing(!editing)} />
          } label="Edit" />
        </Badge>
      </Grid>
      {codeEditor.render}
      {editing && <Button fullWidth variant="contained" color="primary" size="large"
        onClick={handleSave}>Save</Button>}
    </div>
  )
}

export const ImageViewer = ({asset}) => {
  return (
    <div>
      <img src={asset.publish_url ? asset.publish_url : asset.preview_url } 
        style={{width: 'auto', maxWidth: '100%'}} />
    </div>
  )
}