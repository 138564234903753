import React, {useState, useMemo, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AdjustIcon from '@material-ui/icons/Adjust';
import Icon from '@material-ui/core/Icon';

// items: [
//   {
//     title: "List",
//     href: "/news",
//     icon: "list",
//   },
//   {
//     title: "New",
//     href: "/news/new",
//   },
// ]
export default (props) => {
  const [open, setOpen] = useState(props.default)
  const classes = useStyles()
  const render = (
    <React.Fragment>
      <ListItem button onClick={()=>setOpen(!open)}>
        <ListItemIcon>
          {props.icon ? <Icon>{props.icon}</Icon> : <LocalOfferIcon />}
        </ListItemIcon>
        <ListItemText primary={props.label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            props.items.map((item,i)=>{
              return (
                <ListItem key={i} button component="a" href={item.href} className={classes.nested}>
                  <ListItemIcon>
                    {item.icon ? <Icon>{item.icon}</Icon> : <AdjustIcon />}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              )
            })
          }
        </List>
      </Collapse>
    </React.Fragment>
  )
  return {open, render}
}

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))