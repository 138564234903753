import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    '& svg': {
      marginRight: theme.spacing(1),
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}))

export default ({children, icon, title}) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title} component="h2" variant="h6" color="primary" gutterBottom>
        {icon} {title}
      </Typography>
      {children}
    </Paper>
  )
}