import React from 'react'
import { 
  BrowserRouter as Router, 
  Route, Redirect, Switch 
} from "react-router-dom"
import SignInPage from './components/pages/SignInPage'
import ForgotPasswordPage from './components/pages/ForgotPasswordPage'
import {FlashProvider} from './modules/useFlash'

export default () => {
  return (
    <Router>
      <FlashProvider>
        <div className="App">
          <Switch>
            <Route path="/signin" exact component={SignInPage} />
            <Route path="/forgot_password" exact component={ForgotPasswordPage} />
            <Route render={props=><Redirect to="/signin" />} />
          </Switch>
        </div>
      </FlashProvider>
    </Router>
  );
}
