import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CreateIcon from '@material-ui/icons/Create';

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'

import { Wrapper, WrapForm } from 'components/molecules/Wrap'
import newRowsPager from 'components/organisms/newRowsPager'
import ResourceStatusButton from 'components/atoms/ResourceStatusButton'
import useSiteConfig from 'modules/useSiteConfig'

export default (props) => {
  const template_id = props.match.params.template_id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)

  const itemPager = newRowsPager({
    headers: ['slug', 'タイトル', 'path', 'status',],
    onPage: ({offset, limit, updateRows}) => {
      // console.log('offlimit', offset, limit)
      const params = {
        query: {offset, limit}
      }
      setLoading(true)
      Api.fetchAuth(`/templates/${template_id}/assets`, params).then(r=>r.json()).then(response=>{
        updateRows(response.data, response.total)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row, updateRowById}) => (
      <React.Fragment>
        <ListItem {...row} />
        <TableCell>
          <ResourceStatusButton 
            resource_key="defs" 
            resource={row} 
            updateRowById={updateRowById} />
        </TableCell>
      </React.Fragment>
    )
  })
  
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>テンプレートアセット一覧</Grid>
          {/*<Grid item>
            <Button href={`/templates/new`} variant="contained" color="default"
              size="small" startIcon={<AddCircleIcon />}>
              New
            </Button>
          </Grid>*/}
        </Grid>
      </Typography>
      
      {loading && <CircularProgress size={20} />}
      {itemPager.render}
    </Wrapper>
  )
}

const ListItem = (row) => {
  const site = useSiteConfig()
  const url = site.site_origin ? `${site.site_origin}${row.path}` : null
  const resource_path = row.source === 'label' ?
    `/labels/${row.id}` :
    `/defs/${row.definition_id}/resources/${row.id}`
  return (
    <React.Fragment>
      <TableCell>
        <Typography variant="body2">{row.slug}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row.title}</Typography>
      </TableCell>
      <TableCell>
        {url ? 
          <Link href={url} target="_blank"><Typography variant="body2">{row.path}</Typography></Link> :
          <Typography variant="body2">{row.path}</Typography>}
      </TableCell>
      <TableCell>
        <Button component='a' href={resource_path}
          variant="contained"
          color="default"
          size="small"
          startIcon={<CreateIcon />}>
          リソースを編集
        </Button>
      </TableCell>
    </React.Fragment>
  )
}
