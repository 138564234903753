import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
// import SendIcon from '@material-ui/icons/Send';
// import RestoreIcon from '@material-ui/icons/Restore';
// import Helper from './Helper';
import Api from 'utils/Api'
import useFlash from 'modules/useFlash'

export default ({resource_key, resource, removeRowById, ...props}) => {
  const {enqueueSnackbar, handleApiError} = useFlash()

  const onDestroy = () => {
    const res = window.confirm("Are you sure to delete this?")
    Api.fetchAuth(`/${resource_key}/${resource.id}`, {method: "DELETE"}).then(r=>r.json()).then(res=>{
      removeRowById(res.data)
      enqueueSnackbar(res.message, {variant: 'success', persist: false})
    }).catch(handleApiError)
  }

  return (
    <div style={{display: 'inline-block'}}>
      <Grid container alignItems="center">
        <Grid item>
          <DestroyButton onPublish={onDestroy} {...props} />
        </Grid>
      </Grid>
    </div>
  )
}

const DestroyButton = ({onPublish, ...props}) => (
  <Button
    onClick={onPublish}
    variant="contained"
    color="secondary"
    size="small"
    startIcon={<DeleteIcon />}
    {...props}
  >
    Delete
  </Button>
)
