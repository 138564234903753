import React, {useState, useMemo, useEffect} from 'react'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/core/Icon';

import Api, { handleError } from '../../../../../utils/Api'
import useFlash from '../../../../../modules/useFlash'
import {WrapForm, InfoPaper} from '../../../../molecules/Wrap'
import InfoTable from '../../../../molecules/InfoTable'
import InfoMain from '../parts/Information'

import useUpdater from '../../../../../modules/useUpdater'

import {TextEditor, ImageViewer} from '../../../../organisms/MainAssetEditor'

export default (props) => {
  const version_fingerprint = props.match.params.fingerprint
  const id = props.match.params.id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [draftAsset, setDraftAsset] = useState()
  const [lastUpdatedAt, update] = useUpdater()

  useEffect(()=>{
    setLoading(true)

    const subject = Api.fetchAuth(`/main/draft_files/default/${id}`).then(r=>r.json())
    Promise.all([subject]).then(responses=>{
      setDraftAsset(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })
  }, [lastUpdatedAt])

  const onSave = () => {
    update()
  }

  if (loading) return (<CircularProgress />)

  const {version} = draftAsset
  
  return (
    <WrapForm>
      <InfoMain version={version} />
      <InfoTable title="Main Asset" 
        rows={[
          {
            title: "path", 
            content: draftAsset.publish_path, 
            subcontent: draftAsset.publish_url ?
              (<Link href={draftAsset.publish_url} target="_blank">{draftAsset.publish_url}</Link>) :
              null
          },
          {
            title: "content type", 
            content: draftAsset.content_type, 
            subcontent: null,
          },
          {
            title: "preview", 
            content: (
              (<Link href={draftAsset.preview_url} target="_blank"><Icon>open_in_new</Icon></Link>) :
              null
            ), 
            subcontent: null,
          },
        ]}
      />

      {renderContent(draftAsset, {onSave})}
    </WrapForm>
  )
}

const renderContent = (draftAsset, {onSave}) => {
  if (draftAsset.rough_content_type === "text") {
    return (<TextEditor asset={draftAsset} onSave={onSave} />)
  } else if (draftAsset.rough_content_type === "image") {
    return (<ImageViewer asset={draftAsset} />)
  } else {
    return "このコンテンツは表示できません"
  }
}
