import React, {useState} from 'react'
// import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'

import Api from '../../../../../utils/Api'
import useFlash from '../../../../../modules/useFlash'
// import {WrapForm, InfoPaper} from '../../../../molecules/Wrap'

import Uploader from '../../../../molecules/forms/Uploader'

export default ({onDropped}) => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(false)

  const onDrop = acceptedFiles => {
    // TODO: Validates uploaded file, zip, size, ...

    setLoading(true)
    const file = acceptedFiles[0]
    const formData = new FormData()
    formData.append("file", file)
    Api.fetchAuth(`/shared/assets/upload`, {
      method: 'POST',
      body: formData
    }).then(r=>r.json()).then(response=>{
      setLoading(false)
      onDropped(response)
      // console.log(response)
    }).catch(handleApiError)
  }

  if (loading) return (<CircularProgress />)

  return (
    <Uploader onDrop={onDrop} />
  )
}