import React, {useState, useEffect} from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'
import {WrapForm, PaperPadding} from 'components/molecules/Wrap'
import Helper from 'components/atoms/Helper'

import Form from './parts/Form'
import InfoTemplate from 'components/pages/Defs/parts/Information'
import InfoAsset from './parts/Information'

// import useUpdater from 'modules/useUpdater'

export default (props) => {
  const def_id = props.match.params.def_id
  // const id = props.match.params.id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [def, setDef] = useState()

  useEffect(()=>{
    setLoading(true)
    
    const api1 = Api.fetchAuth(`/defs/${def_id}`).then(r=>r.json())

    Promise.all([api1]).then(responses=>{
      setDef(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })

  }, [])

  const save = (body) => {
    // console.log('body', body)
    return Api.fetchAuth(`/defs/${def_id}/resources`, {
      method: 'POST',
      body: body
    }).then(r=>r.json()).then(response=>{
      window.location.href = `/defs/${def_id}/resources`
    }).catch((error)=>{
      handleApiError(error)
      return Promise.resolve()
    })
  }

  if (loading) return <CircularProgress />

  return (
    <>
      <PaperPadding>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item>
            <Link href={`/defs/${def.id}`}>リソース定義を変更</Link>
          </Grid>
          <Grid item>
            <Link href={`/defs/${def.id}/resources`}>リソース一覧</Link>
          </Grid>
        </Grid>
      </PaperPadding>
      <WrapForm>
        <Typography variant="h6" gutterBottom>リソース {def.slug} 新規作成</Typography>
        <Form {...{save, definition: def}} />
      </WrapForm>
    </>
  )
}
