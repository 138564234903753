import ActionCable from "actioncable"
import AuthToken from 'utils/AuthToken'
import Env from 'utils/Env'

let CONSUMER = null
export default CONSUMER

export const getConsumer = () => {
  const url = `${Env.ws_endpoint}?token=${AuthToken.token}`
  if ( ! CONSUMER || CONSUMER.url !== url ) {
    CONSUMER = ActionCable.createConsumer(url) 
  }
  return CONSUMER
}