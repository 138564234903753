import React from 'react'
// import Api, { handleError } from '../../../../../utils/Api'
// import useFlash from '../../../../molecules/useFlash'
import GeneratedForm from 'components/organisms/GeneratedForm'

import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import newSlugField from 'components/molecules/forms/newSlugField'
import newTextField from 'components/molecules/forms/newTextField'
import newKeyValueFields from 'components/molecules/forms/newKeyValueFields'
import newSelectableSearchableLabels from 'components/molecules/forms/newSelectableSearchableLabels'
import newSelectableAssets from 'components/molecules/forms/newSelectableAssets'
// import newCodeEditor from 'components/molecules/forms/newCodeEditor'
import newPolymorphicEditor from 'components/molecules/forms/newPolymorphicEditor'
import newSchemaField from 'components/molecules/forms/newSchemaField'
import HelpTip from 'components/atoms/HelpTip'

export default ({labelMaster, save, definition, subject}) => {
  subject = subject || {}
  const slug = newTextField({
    defaultValue: subject.slug, label: "Slug", disabled: !!subject.slug,
    fullWidth: true, helperText: subject.slug ? null : '何も入力しないとランダムのslugが発行されます',
  })
  const title = newTextField({default: subject.title, label: "Title", fullWidth: true})
  const argsDef = definition.arguments || {}
  const defaultArgs = Object.keys(argsDef).reduce((acc,k)=>{
    acc[k] = argsDef[k].default
    return acc
  }, {})
  const variables = newSchemaField({
    defaultValue: subject.variables || defaultArgs,
    schema: argsDef,
    label: "Arguments", keyLabel: "変数", valueLabel: "値",
  })
  const labels = newSelectableSearchableLabels({
    default: subject.labels || [], label: "Labels",
    baseQuery: { limit: 20 },
  })
  // const codeEditor = newCodeEditor({defaultValue: subject.content})
  const codeEditor = newPolymorphicEditor({
    defaultValue: subject.content, defaultMode: subject.content_edit_mode,
  })
  const images = newSelectableAssets({
    default: subject.images,
    max: 10,
    // baseQuery: {kind: 'resource'}
  })
  const getBody = () => ({
    slug: slug.value,
    title: title.value,
    variables: variables.values,
    content: codeEditor.html,
    label_ids: labels.values.map(x=>x.id),
    image_ids: images.values.map(x=>x.id),
    content_edit_mode: codeEditor.mode,
  })

  return (
    <React.Fragment>
      <GeneratedForm handleSave={()=>save(getBody())} objects={[
        slug,
        title,
        variables, 
        labels,
        codeEditor,
        images,
      ]} />
      {subject.id && (
        <HelpTip>
          <b>SAVE</b> でアセット内容を変更しても、ウェブサイトには即時反映されません。<br/>
          即時反映するには、<b>SAVE</b>したのち、<b>DEPLOY</b>を実行します。
        </HelpTip>
      )}
    </React.Fragment>
  )
}