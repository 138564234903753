import React from 'react'
import GeneratedForm from '../../../organisms/GeneratedForm'
// import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import newTextField from '../../../molecules/forms/newTextField'
// import newKeyValueFields from '../../../molecules/forms/newKeyValueFields'
import newNestedKeyValueFields from '../../../molecules/forms/newNestedKeyValueFields'
import newCodeEditor from '../../../molecules/forms/newCodeEditor'

import HelpTip from '../../../atoms/HelpTip'
// import copyToClipboard from '../../../../utils/copyToClipboard'

export default ({labelMaster, save, subject}) => {
  subject = subject || {}
  const slug = newTextField({default: subject.slug, label: "Slug", fullWidth: true, disabled: !!subject.slug})
  // const title = newTextField({default: subject.title, label: "Title", fullWidth: true})
  // const path = newTextField({default: subject.path, label: "Path", fullWidth: true})
  
  // const templateArgs = newKeyValueFields({
  //   default: subject.arguments,
  //   label: "Arguments", keyLabel: "変数", valueLabel: "デフォルト値",
  // })
  const templateArgs = newNestedKeyValueFields({
    default: subject.arguments,
    label: "Arguments", keyLabel: "変数", valueLabel: "デフォルト値",
    schema: {
      default: { label: "デフォルト値", type: "string" },
      required: { label: "必須", type: "boolean" },
    }
  })
  const labelConfig = newNestedKeyValueFields({
    default: subject.label_config,
    label: "ラベリング", keyLabel: "ラベル識別子", valueLabel: "設定",
    schema: {
      path_prefix: { label: "Pathプレフィックス", type: "string" },
      single: { label: "Single", type: "boolean" },
    }
  })
  // const codeEditor = newCodeEditor({
  //   default: subject.content,
  //   style: {
  //     fontSize: '1rem',
  //     width: 400,
  //   }
  // })
  const body = {
    slug: slug.value,
    // title: title.value,
    // path: path.value,
    arguments: templateArgs.values,
    label_config: labelConfig.values,
    // content: codeEditor.value,
  }
  const defaultArgs = ["id", "slug", "title", "content"]
  const labelArgs = ["id", "path", "slug", "name"]
  const availableArgs = [...(new Set(
    defaultArgs.concat(
      Object.keys(templateArgs.values)
    )
    // .concat(
    //   ...Object.keys(labelConfig.values).map(x=>labelArgs.map(y=>`${x}.${y}`))
    // )
  ))]

  return (
    <React.Fragment>
      <GeneratedForm handleSave={()=>save(body)} objects={[
        (subject.slug ? slug : [slug, (
          <HelpTip>
            slugは、このテンプレートを識別するユニークな文字列です。<br/>
            apiを利用する際もslug名がつかわれ、あとから変更することはできません。
            設定例: news, blog
          </HelpTip>
        )]),
        // title, path,
        // (
        //   <HelpTip>
        //     pathに指定したルールでURLが動的に生成されます。
        //     必ず <mark><b>/</b></mark> から始めてください。<br/>
        //     また、各ページにユニークな値となる変数 <mark><b>{'{{id}}'}</b></mark>, <mark><b>{'{{slug}}'}</b></mark> を含めることが推奨されます。
        //     これらの変数がpathに含まれない場合、複数ページのURLが重複し、到達できないページがあらわれる可能性があります。<br/>
        //     設定例: <mark>{'/news/{{slug}}'}</mark>, <mark>{'/blog/category/{{category}}/{{slug}}'}</mark>
        //   </HelpTip>
        // ),
        templateArgs, 
        (
          <HelpTip>
            このテンプレートで利用可能にする変数を設定します。<br/>
            {defaultArgs.join(', ')} の{defaultArgs.length}つの変数はデフォルトで用意されるので、変数欄では設定しないでください。<br/>
            変数名はかならずアルファベット小文字からはじまる必要があります。
            ここで設定した変数は、pathおよびテンプレート本文内で利用できるようになります。利用する際は、<mark><b>{'{{id}}'}</b></mark>のように、<mark><b>{'{{}}'}</b></mark>で囲んでください。<br/>
            設定例: category, tag, city_name
          </HelpTip>
        ),
        labelConfig,
        // (templateArgs.values && 
        //   <div>
        //     <p>テンプレート内で次の変数が利用できます。</p>
        //     {
        //       availableArgs.length ? 
        //         availableArgs.map((k,i)=>{
        //           return (<Chip key={`v-${i}`} variant="outlined" size="small"
        //             label={`\{\{${k}\}\}`} onClick={(e)=>{}} />)
        //         }) :
        //         (<span>利用できる変数はありません</span>)
        //     }
        //   </div>),
        // codeEditor
      ]} />

      {subject.slug && (<HelpTip>
        <b>SAVE</b> でテンプレート内容を変更しても、ウェブサイトには反映されません。<br/>
        ウェブサイトに反映させるためには、<b>SAVE</b>したのち、<b>PUBLISH ALL</b>を実行します。
      </HelpTip>)}
    </React.Fragment>
  )
}