import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import StarsIcon from '@material-ui/icons/Stars';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

export default ({permission}) => {
  const label = permission.ability_kind.toUpperCase()
  if (permission.ability_kind === 'master') return <PermissionMaster label={label} />
  if (permission.ability_kind === 'admin') return <PermissionAdmin label={label} />
  if (!permission.party_id) return <PermissionInternational label={label} />
  return <PermissionRegular label={label} />
}

export const PermissionAdmin = ({label}) => {
  const classes = useStyles()
  return (
    <Chip
      avatar={<Avatar><FaceIcon /></Avatar>}
      label={label}
      className={classes.chip}
      variant="outlined"
      color="secondary"
    />
  )
}
export const PermissionRegular = ({label}) => {
  const classes = useStyles()
  return (
    <Chip
      avatar={<Avatar><FaceIcon /></Avatar>}
      label={label}
      className={classes.chip}
      variant="outlined"
      color="primary"
    />
  )
}
export const PermissionInternational = ({label}) => {
  const classes = useStyles()
  return (
    <Chip
      avatar={<Avatar><SupervisorAccountIcon /></Avatar>}
      label={label}
      className={classes.chip}
      variant="filled"
      color="default"
    />
  )
}
export const PermissionMaster = ({label}) => {
  const classes = useStyles()
  return (
    <Chip
      avatar={<Avatar><StarsIcon /></Avatar>}
      label={label}
      className={classes.chip}
      color="secondary"
    />
  )
}
