import React, {useState} from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import newTextField from 'components/molecules/forms/newTextField'
import newSelect from 'components/molecules/forms/newSelect'
// import newNestedKeyValueFields from 'components/molecules/forms/newNestedKeyValueFields'
import newCodeEditor from 'components/molecules/forms/newCodeEditor'

import HelpTip from 'components/atoms/HelpTip'
import Modal80 from 'components/atoms/Modal80'
import copy from 'clipboard-copy'

export default ({save, subject, defs}) => {
  subject = subject || {}
  const slug = newTextField({default: subject.slug, label: "slug", fullWidth: true, disabled: !!subject.slug})
  const title = newTextField({default: subject.title, label: "Title", fullWidth: true})
  const path = newTextField({default: subject.path, label: "Path", fullWidth: true})

  const source = newSelect({
    label: "ソース",
    options: ["label", ...defs.map(x=>x.slug)].reduce((acc,i)=>{acc[i] = i; return acc}, {})
  })
  const source_identifier = newTextField({
    label: "識別子",
  })
  const sourceComponent = subject.source ?
    (<TextField disabled fullWidth label="ソース" value={`${subject.source} ${JSON.stringify(subject.condition)}`}></TextField>) :
    (
      <Grid container spacing={2}>
        <Grid item>{source.render}</Grid>
        <Grid item>{source.value === 'label' && source_identifier.render}</Grid>
      </Grid>
    )
  
  const codeEditor = newCodeEditor({
    label: "Body",
    defaultValue: subject.content,
    style: {
      fontSize: '1rem',
      width: 400,
    }
  })
  const body = {
    slug: slug.value,
    source_identifier: subject.id ? null : [source.value, source_identifier.value],
    title: title.value,
    path: path.value,
    content: codeEditor.value,
  }
  const defaultArgs = ["id", "slug", "title", "content"]
  const labelArgs = ["id", "path", "slug", "name"]

  return (
    <React.Fragment>
      <GeneratedForm handleSave={()=>save(body)} objects={[
        (subject.slug ? slug : [slug, (
          <HelpTip>
            slugは、このテンプレートを識別するユニークな文字列です。<br/>
            apiを利用する際もslug名がつかわれ、あとから変更することはできません。
            設定例: news, blog
          </HelpTip>
        )]),
        sourceComponent,
        title, path,
        (
          <HelpTip>
            pathに指定したルールでURLが動的に生成されます。
            必ず <mark><b>/</b></mark> から始めてください。<br/>
            また、各ページにユニークな値となる変数 <mark><b>{'{{id}}'}</b></mark>, <mark><b>{'{{slug}}'}</b></mark> を含めることが推奨されます。
            これらの変数がpathに含まれない場合、複数ページのURLが重複し、到達できないページがあらわれる可能性があります。<br/>
            設定例: <mark>{'/news/{{slug}}'}</mark>, <mark>{'/blog/category/{{category}}/{{slug}}'}</mark>
          </HelpTip>
        ),
        codeEditor,
        (<AvailableVariableModal variables={subject.available_variables} />)
      ]} />

      {subject.slug && (<HelpTip>
        <b>SAVE</b> でテンプレート内容を変更しても、ウェブサイトには反映されません。<br/>
        ウェブサイトに反映させるためには、<b>SAVE</b>したのち、<b>PUBLISH ALL</b>を実行します。
      </HelpTip>)}
    </React.Fragment>
  )
}

const AvailableVariableModal = ({variables}) => {
  // const [opened, setOpened] = useState(true)
  return (
    <React.Fragment>
      <InputLabel shrink>利用可能な変数一覧</InputLabel>
      <Typography variant="body">
        <p>
          x は数値で置き換えて仕様できます。 例: <mark>areas.x.name</mark> => <mark>areas.0.name</mark><br/>
          htmlをエスケープせずに表示するには、波括弧を３つ並べます。 例: <mark>{'{{{content}}}'}</mark><br/>
          詳しくは<Link target="_blank" href="https://mustache.github.io/#demo">Mustache</Link>仕様を参照してください。
        </p>
      </Typography>
      <Grid container column="row" spacing={1}>
        {(variables||[]).map(x=><Grid item><AvailableVariable name={x} /></Grid>)}
      </Grid>
      {/*<Modal80 header='test test' opened={opened} onClose={()=>setOpened(false)}>
      </Modal80>*/}
      {/*<Chip label="利用可能な変数を表示" onClick={()=>setOpened(true)} />*/}
    </React.Fragment>
  )
}
const AvailableVariable = ({name}) => {
  const syntax = name === 'content' ? `{{{${name}}}}` : `{{${name}}}`
  return (
    <Chip size="small" label={syntax} onClick={()=>copy(syntax)} />
  )
}