import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'
import { WrapForm, InfoPaper } from 'components/molecules/Wrap'

import Form from './parts/Form'

export default () => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    setLoading(false)
  }, [])

  const save = (body) => {
    console.log('body', body)
    setLoading(true)
    Api.fetchAuth('/redirect_rules', {
      method: 'POST',
      body: body
    }).then(r=>r.json()).then(response=>{
      setLoading(false)
      window.location.href = '/site/redirect_rules'
    }).catch((error)=>{
      setLoading(false)
      handleApiError(error)
    })
  }

  return (
    <WrapForm>
      { loading && (<CircularProgress />)}
      <Typography variant="h6" gutterBottom>リダイレクト 新規作成</Typography>
      <Form {...{save}} />
      { loading && (<CircularProgress />)}
    </WrapForm>
  )
}
