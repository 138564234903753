import React, {useState, useEffect} from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
// import Button from '@material-ui/core/Button';
// import Icon from '@material-ui/core/Icon';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import { WrapForm, PaperPadding } from 'components/molecules/Wrap'
import {ButtonInlineRelease, ButtonReplaceRelease} from 'components/atoms/ButtonRelease'

import Form from './parts/Form'
import Information from './parts/Information'

import useUpdater from 'modules/useUpdater'

export default (props) => {
  const id = props.match.params.id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [subject, setSubject] = useState()
  const [defs, setDefs] = useState()
  const [lastUpdatedAt, update] = useUpdater()

  useEffect(()=>{
    setLoading(true)
    
    const api1 = Api.fetchAuth(`/templates/${id}`).then(r=>r.json())
    const api2 = Api.fetchAuth(`/defs`).then(r=>r.json())

    Promise.all([api1, api2]).then(responses=>{
      setSubject(responses[0].data)
      setDefs(responses[1].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })

  }, [lastUpdatedAt])

  const save = (body) => {
    // console.log('body', body)
    return Api.fetchAuth(`/templates/${id}`, {
      method: 'PUT',
      body: body
    }).then(r=>r.json()).then(response=>{
      update()
      return Promise.resolve()
    }).catch((error)=>{
      handleApiError(error)
      return Promise.resolve()
    })
  }

  if (loading) return <CircularProgress />

  return (
    <>
      <PaperPadding>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item>
            <ButtonInlineRelease endpoint={`/templates/${id}`} />
          </Grid>
          <Grid item>
            <ButtonReplaceRelease endpoint={`/templates/${id}`} />
          </Grid>
          <Grid item>
            <Link href={`/templates/${id}/assets`}>{subject.assets_count}件のアセット</Link>
          </Grid>
        </Grid>
      </PaperPadding>
      <WrapForm>
        <Typography variant="h6" gutterBottom>テンプレート</Typography>
        <Form {...{save, subject, defs}} />
      </WrapForm>
    </>
  )
}
