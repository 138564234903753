import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2),
  },
}));

export default (props) => {
  const {children} = props
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  return (
      <div {...props}>
        <IconButton aria-label="help" onClick={(e)=>setAnchorEl(e.currentTarget)}>
          <HelpIcon />
        </IconButton>
        
        <Popover
          id={'help'}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={()=>setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <div className={classes.content}>
            {children}
          </div>
        </Popover>
      </div>
  )
}
