import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'
import { WrapForm, InfoPaper } from 'components/molecules/Wrap'

import Form from './parts/Form'

export default () => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [defs, setDefs] = useState()

  useEffect(()=>{
    setLoading(true)

    const api1 = Api.fetchAuth(`/defs`).then(r=>r.json())

    Promise.all([api1]).then(responses=>{
      setDefs(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })
  }, [])

  const save = (body) => {
    console.log('body', body)
    setLoading(true)
    Api.fetchAuth('/templates', {
      method: 'POST',
      body: body
    }).then(r=>r.json()).then(response=>{
      setLoading(false)
      window.location.href = '/templates'
    }).catch((error)=>{
      setLoading(false)
      handleApiError(error)
    })
  }
  
  return (
    <WrapForm>
      { loading && (<CircularProgress />)}
      <Typography variant="h6" gutterBottom>テンプレート 新規作成</Typography>
      {defs && <Form {...{save, defs}} />}
      { loading && (<CircularProgress />)}
    </WrapForm>
  )
}
