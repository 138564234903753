import React, {useState, useEffect} from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
// import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import {Wrapper} from 'components/molecules/Wrap'

import TableCell from '@material-ui/core/TableCell';
// import ResourceStatusButton from 'components/atoms/ResourceStatusButton'
import newRowsPager from 'components/organisms/newRowsPager'

export default (props) => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  useEffect(()=>{
    setLoading(true)
  }, [])


  const itemPager = newRowsPager({
    headers: ['slug', 'status'],
    onPage: ({offset, limit, updateRows}) => {
      const params = {
        query: {offset, limit}
      }
      setLoading(true)
      Api.fetchAuth(`/components`, params).then(r=>r.json()).then(response=>{
        updateRows(response.data, response.total)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row, updateRowById}) => (
      <React.Fragment>
        <ListItem {...row} />
        {/*<TableCell>
          <ResourceStatusButton 
            resource_key={`components`}
            resource={row} 
            updateRowById={updateRowById} />
        </TableCell>*/}
      </React.Fragment>
    )
  })
  
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>コンポーネント 一覧</Grid>
          <Grid item>
            <Button href={`/components/new`} variant="contained" color="default"
              size="small" startIcon={<AddCircleIcon />}>
              New
            </Button>
          </Grid>
        </Grid>
      </Typography>
      
      {loading && <CircularProgress size={20} />}
      {itemPager.render}
    </Wrapper>
  )
}

const ListItem = (row) => (
  <React.Fragment>
    <TableCell>
      <Link href={`/components/${row.id}`} color="inherit">
        <Typography variant="body2">{row.slug}</Typography>
      </Link>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{row.status}</Typography>
    </TableCell>
  </React.Fragment>
)

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
