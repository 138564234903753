import React, {useState, useMemo, useEffect, useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal'

export default ({children, header, ...props}) => {
  const classes = useStyles()
  
  return (
    <Modal {...props}>
      <div style={{top:0,bottom:0,left:0,right:0}} className={classes.paper}>
        {header}
        <div className={classes.paperInner}>
          {children}
        </div>
      </div>
    </Modal>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '80%',
    height: '80%',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
  },
  paperInner: {
    width: '100%',
    // height: '100%',
    flexGrow: 1,
    overflowY: 'scroll',
  }
}))
