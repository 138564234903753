import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {useSuperCache} from 'modules/SuperCache'
import {
  StorageProgress,
  DomainProgress,
  FrontProgress,
  CertificateProgress,
  DnsProgress,
  PublishedMainProgress,
  PublishedTemplateProgress,
} from 'components/organisms/InfraProgress'

import ButtonRelease from 'components/atoms/ButtonRelease'
import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import newTextField from 'components/molecules/forms/newTextField'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    '& svg': {
      marginRight: theme.spacing(1),
    }
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default () => {
  const {me} = useSuperCache()
  const {party} = me
  const {
    release_main_version_fingerprint,
    release_templates,
  } = party
  const classes = useStyles()

  const {handleApiError} = useFlash()
  const smartReleaseVersion = newTextField({
    placeholder: 'main version 例: 8d20dccce7'
  })
  const onSmartRelease = () => {
    const version = smartReleaseVersion.value
    if (! version) {
      const res1 = window.confirm(
        "最新のメインバージョンがリリースされます。よろしいですか？リリースバージョンを変更したい場合は、バージョンを指定してください。例: 8d20dccce7"
      )
      if (!res1) return
    } else {
      const res2 = window.confirm("サイトを更新しますか？")
      if (!res2) return
    }
    Api.fetchAuth(`/smart_release`, {
      method: 'POST',
      body: {version: version},
    }).then(r=>r.json()).then(response=>{
      // if (onReleased) onReleased()
    }).catch(handleApiError)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <div style={{marginBottom: 4}}>
          <StorageProgress party={party} />
        </div>
        <DomainProgress party={party} />
      </Grid>
      <Grid item xs={12} md={4}>
        <div style={{marginBottom: 4}}>
          <FrontProgress party={party} />
        </div>
        <CertificateProgress party={party} />
      </Grid>
      <Grid item xs={12} md={4}>
        <DnsProgress party={party} />
      </Grid>

      <Grid container item xs={12} direction="column" spacing={1}>
        <Grid item>
          <ButtonRelease label="スマートリリース" onRelease={onSmartRelease} />
          {smartReleaseVersion.render}
        </Grid>
        <Grid item><PublishedMainProgress party={party} /></Grid>
        <Grid item><PublishedTemplateProgress party={party} /></Grid>
      </Grid>

      <Grid item xs={12}>
      </Grid>
    </Grid>
  )
}
