import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import InfoTable from '../../../../molecules/InfoTable'
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';

// import ButtonPublish from 'components/atoms/ButtonPublish'
import {ButtonInlineRelease, ButtonReplaceRelease} from 'components/atoms/ButtonRelease'
import ButtonDownload from 'components/atoms/ButtonDownload'

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'

export default ({version, onPublish}) => {
  const shortFingerprint = version.fingerprint.substr(0, 10)
  const {handleApiError} = useFlash()
  const handleDownload = () => {
    Api.fetchAuth(`/main/versions/${version.id}/download`).then(r=>r.blob()).then(res=>{
      console.log('res', res)
      const uri = URL.createObjectURL(res)
      const link = document.createElement("a")
      // link.download = 'data.zip'
      link.href = uri
      link.click()
    }).catch(handleApiError)
  }
  return (
    <InfoTable title="main version" rows={[
      {
        title: "version",
        content: (
          <Typography variant="body2" color={version.is_published ? 'default' : 'error'}>
            <Link href={`/main/versions/${version.fingerprint}`}>
              <mark>{shortFingerprint}</mark>
            </Link>
            { version.is_published ? " がリリース済み" : " はリリースされていません" }      
          </Typography>
        ),
        subcontent: (
          <Grid container spacing={1} direction="row">
            <Grid item><ButtonInlineRelease endpoint={`/main/versions/${version.id}`} /></Grid>
            <Grid item><ButtonReplaceRelease endpoint={`/main/versions/${version.id}`} /></Grid>
          </Grid>
        )
        // subcontent: ! version.is_published && onPublish ?
        //   (<ButtonPublish onPublish={onPublish} />) :
        //   null,
      },
      {
        title: "download",
        content: (<ButtonDownload onClick={()=>handleDownload()} />),
        subcontent: null,
      },
      {
        title: "preview",
        content: version.preview_domain ? 
          (<Link href={`//${version.preview_domain}`}><Icon>open_in_new</Icon></Link>) :
          null,
        subcontent: null,
      },
    ]} />
  )
}