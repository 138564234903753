import React from 'react'

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import StorageIcon from '@material-ui/icons/Storage';
import DomainIcon from '@material-ui/icons/Domain';
import HttpsIcon from '@material-ui/icons/Https';
import PublicIcon from '@material-ui/icons/Public';
import DnsIcon from '@material-ui/icons/Dns';
import ErrorIcon from '@material-ui/icons/Error';
import Check from '../atoms/icons/Check'
import IconTypography from '../atoms/IconTypography'
import TitledPaper from '../atoms/TitledPaper'

const Completed = ({title})=><IconTypography icon={<Check fontSize='inherit' />}>{title || 'Completed'}</IconTypography>
const InCompleted = ({title})=><IconTypography icon={<ErrorIcon color='error' fontSize='inherit' />}>{title}</IconTypography>

const NotCompleted = ()=><InCompleted title="Not Completed" />
const Pending = ()=><InCompleted title="Pending" />
const CanBeStarted = ()=><InCompleted title="Can be Started" />

export const StorageProgress = ({party}) => {
  return (
    <TitledPaper icon={<StorageIcon />} title="Storage">
      {
        party.s3_bucket_name ? (
          <Completed />
        ) : (
          <NotCompleted />
        )
      }
    </TitledPaper>
  )
}
export const DomainProgress = ({party}) => {
  const release_domains = party.release_domains || []
  const secondProgress = () => {
    if (release_domains.length <= 0) return null
    if (party.has_hosted_zone && !party.hosted_zone_id) {
      return (<InCompleted title="Domain Not Prepared" />)
    }
    if (party.has_hosted_zone && party.hosted_zone_id) {
      return (<Completed title="Domain Prepared" />)
    }
  }

  return (
    <TitledPaper icon={<DomainIcon />} title="Domain">
      {
        release_domains.length ?
          (<Completed title="Release Domain Fixed" />) :
          (<InCompleted title="Release Domain Not Fixed" />)
      }
      { secondProgress() }
    </TitledPaper>
  )
}

export const FrontProgress = ({party}) => {
  return (
    <TitledPaper icon={<PublicIcon />} title="Front">
      {
        party.cloud_front_distribution_id ? (
          <Completed />
        ) : (
          <NotCompleted />
        )
      }
    </TitledPaper>
  )
}

export const CertificateProgress = ({party}) => {
  const release_domains = party.release_domains || []
  const renderProgress = () => {
    // if (!party.cloud_front_distribution_id) return (<InCompleted title="Front Not Prepared" />)
    if (release_domains.length <= 0) return (<InCompleted title="Release Domain Not Fixed" />)
    if (party.has_hosted_zone && !party.hosted_zone_id) {
      return (<InCompleted title="Domain Not Prepared" />)
    }
    if (!party.primary_acm_certificate_arn) return (<CanBeStarted />)
    if (party.primary_acm_status !== 'issued') return (<Pending />)
    return (<Completed title="HTTPS will be enabled after first PUBLISH" />)
  }

  return (
    <TitledPaper icon={<HttpsIcon />} title="Certificate">
      {renderProgress()}
    </TitledPaper>
  )
}

export const DnsProgress = ({party}) => {
  const release_domains = party.release_domains || []
  const renderProgress = () => {
    if (release_domains.length <= 0) return (<InCompleted title="Release Domain Not Fixed" />)
    if (!party.cloud_front_distribution_id) return (<InCompleted title="Front Not Prepared" />)
    if (!party.has_hosted_zone) {
      const domain = release_domains[0]
      return (
        <Completed title={`${domain} CNAME ${party.cloud_front_domain}`} />
      )
    }
    if (!party.hosted_zone_id) return (<InCompleted title="Domain Not Prepared" />)
    // TODO: Check DNS set up finished
    if ( 'TODO: CHECK_DNS_SETUP_FINISHED' ) {
      return (<Completed />)
    } else {
      return (<CanBeStarted />)
    }
  }
  return (
    <TitledPaper icon={<DnsIcon />} title="DNS">
      {renderProgress()}
    </TitledPaper>
  )
}

export const PublishedMainProgress = ({party}) => {
  const {release_main_version_fingerprint} = party

  const renderProgress = () => {
    if (!release_main_version_fingerprint) {
      return (<InCompleted title="Not Published" />)
    }
    const shortPrint = release_main_version_fingerprint.substr(0,7)
    return (
      <Completed title={
        <Typography>
          Released {' '}
          <Link href={`/main/versions/${release_main_version_fingerprint}`}>
            <mark>{shortPrint}</mark>
          </Link>
        </Typography>
      } />
    )
  }
  return (
    <TitledPaper title="Published Main">
      <div style={{marginBottom: 8}}>
        {renderProgress()}
      </div>
      <div>
        <Link color="primary" href={`/main/versions/new`}>
          新しいバージョンを作成
        </Link>
      </div>
    </TitledPaper>
  )
}

export const PublishedTemplateProgress = ({party}) => {
  const release_templates = party.release_templates || []
  const renderProgress = () => {
    if (release_templates.length<=0) return (<InCompleted title="No Published Template" />)
    return release_templates.map(x=>{
      const shortPrint = x.version.substr(0, 7)
      return (
        <Completed key={x.id} title={
          <Typography>
            <Chip component="span" label={x.slug} size="small" style={{marginRight: 4}} /> 
            Released {' '}
            <Link href={`/templates/${x.id}`}><mark>{shortPrint}</mark></Link>
          </Typography>
        } />
      )
    })
  }
  return (
    <TitledPaper title="Published Template">
      <div style={{marginBottom: 8}}>
        {renderProgress()}
      </div>
      <div>
        <Link color="primary" href={`/templates/new`}>
          新しいテンプレートを作成
        </Link>
      </div>
    </TitledPaper>
  )
}
