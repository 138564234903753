import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';
import SendIcon from '@material-ui/icons/Send';
import Helper from './Helper';

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'

const ButtonRelease = ({children, label, onRelease, startIcon, color}) => {
  return (
    <div style={{display: 'inline-block'}}>
      <Grid container alignItems="center">
        <Grid item>
          <Button
            onClick={onRelease}
            variant="contained"
            color={color || "primary"}
            size="small"
            startIcon={startIcon || (<CachedIcon />)}>
            {label || "Release"}
          </Button>
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </div>
  )
}

export const ButtonInlineRelease = ({children, endpoint, onReleased}) => {
  const {handleApiError} = useFlash()
  return ButtonRelease({children, label: "インラインリリース", 
    startIcon: <SendIcon />,
    onRelease: ()=>{
      const res = window.confirm("Are you sure to release this template?")
      if (!res) return
      Api.fetchAuth(`${endpoint}/inline_release`, {
        method: 'POST'
      }).then(r=>r.json()).then(response=>{
        if (onReleased) onReleased()
      }).catch(handleApiError)
    }
  })
}
export const ButtonReplaceRelease = ({children, endpoint, onReleased}) => {
  const {handleApiError} = useFlash()
  return ButtonRelease({children, label: "リプレースリリース", color: "secondary",
    onRelease: ()=>{
      const res = window.confirm("Are you sure to release this template?")
      if (!res) return
      Api.fetchAuth(`${endpoint}/replace_release`, {
        method: 'POST'
      }).then(r=>r.json()).then(response=>{
        if (onReleased) onReleased()
      }).catch(handleApiError)
    }
  })
}
export default ButtonRelease