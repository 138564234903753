import React from 'react'
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
  darken,
} from '@material-ui/core/styles';
import {blue, pink} from '@material-ui/core/colors'

function getCookie(name) {
  const regex = new RegExp(`(?:(?:^|.*;*)${name}*=*([^;]*).*$)|^.*$`);
  return document.cookie.replace(regex, '$1');
}

export default (props) => {
  const { children } = props
  const paletteType = getCookie('paletteType') || 'dark'

  React.useEffect(() => {
    document.cookie = `paletteType=${paletteType};path=/;max-age=31536000`;
  }, [paletteType]);

  const theme = React.useMemo(() => {
    const nextTheme = createMuiTheme({
      nprogress: {
        color: paletteType === 'light' ? '#000' : '#fff',
      },
      palette: {
        primary: {
          main: paletteType === 'light' ? blue[700] : blue[200],
        },
        secondary: {
          main: paletteType === 'light' ? darken(pink.A400, 0.1) : pink[200],
        },
        type: paletteType,
        common: {
          green: '#52fa92',
        },
        error: {
          main: paletteType === 'light' ? '#e82856' : '#f83161',
        },
      },
    })

    nextTheme.palette.background.level2 =
      paletteType === 'light' ? nextTheme.palette.grey[100] : '#333';

    nextTheme.palette.background.level1 =
      paletteType === 'light' ? '#fff' : nextTheme.palette.grey[900];

    return nextTheme;
  }, [paletteType])

  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
}