import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'

import Api, { buildLimitOffsetQuery } from 'utils/Api'
import useFlash from 'modules/useFlash'
import useUpdater from 'modules/useUpdater'
import {WrapForm, Section} from 'components/molecules/Wrap'

import AssetList from 'components/organisms/AssetList'
import AssetCard from 'components/molecules/AssetCard'
import Upload from './parts/Upload'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'

const useStyles = makeStyles(theme => ({
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& img': {
      maxWidth: 300,
    },
  },
  contentArea: {
    maxWidth: '100%',
    width: 320,
    height: '100%',
  },
  block: {
    marginBottom: theme.spacing(2),
  }
}))

export default (props) => {
  const query = buildLimitOffsetQuery({location_search: props.location.search})
  
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [assets, setAssets] = useState()
  const [lastUpdatedAt, update] = useUpdater()

  const [selectedAsset, selectAsset] = useState(null)
  const resetAsset = () => selectAsset(null)
  const classes = useStyles()

  useEffect(()=>{
    setLoading(true)

    const getAssets = Api.fetchAuth(`/shared/assets`, {query}).then(r=>r.json())

    Promise.all([getAssets]).then(responses=>{
      setAssets(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })
  }, [lastUpdatedAt])

  if (loading) return (<CircularProgress />)

  return (
    <WrapForm>
      <Section title="Upload Shared Assets">
        <Upload onDropped={()=>update()} />
      </Section>
      
      <Section title="List of Shared Assets">
        <AssetList items={assets} onClick={(item)=>selectAsset(item)} />
      </Section>

      <Modal
        aria-labelledby="agl-modal-title"
        aria-describedby="agl-modal-description"
        open={!!selectedAsset}
        onClose={()=>resetAsset()}>
        <div className={classes.modalPaper}>
          <ModalInner item={selectedAsset} onClick={()=>resetAsset()} />
        </div>
      </Modal>
    </WrapForm>
  )
}

const ModalInner = ({item, onClick}) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <h2 id="agl-modal-title">{item.title}</h2>

      <div className={classes.block}>
        <TextField value={item.publish_path} id="copyPath" />
        <Button onClick={()=>{
          const target = document.getElementById("copyPath");
          target.select()
          target.setSelectionRange(0, 99999)
          document.execCommand("copy")
        }}>Copy</Button>
      </div>
      {item.publish_url && 
        <div className={classes.block}>
          <TextField value={item.publish_url} id="copyUrl" />
          <Button onClick={()=>{
            const target = document.getElementById("copyUrl");
            target.select()
            target.setSelectionRange(0, 99999)
            document.execCommand("copy")
          }}>Copy</Button>
        </div>
      }
      
      <div className={classes.contentArea} onClick={onClick}>
        <AssetCard item={item} onClick={()=>{}} />
      </div>
    </React.Fragment>
  )
}