import React from 'react'
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export default (props) => {
  return (
    <Button
      variant="contained"
      color="default"
      size="small"
      {...props}
      startIcon={<CloudDownloadIcon />}>
      Download
    </Button>
  )
}