import React from 'react';

export default (props) => {
  return (
    <div className="App">
      <h1>Error occured.</h1>
      <p>
        {props.error.statusText}
      </p>
    </div>
  );
}
