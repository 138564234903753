import React, {useState,useEffect} from 'react'
import { 
  BrowserRouter as Router, 
  Route, Redirect, Switch 
} from "react-router-dom"
import logo from './logo.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import {SuperCacheProvider, useSuperCache} from './modules/SuperCache'
import {FlashProvider} from './modules/useFlash'
import ThemeProvider from './ThemeProvider'
import Header from 'components/organisms/Header'
import Dashboard from 'components/organisms/Dashboard'
import Standalone from 'components/organisms/Standalone'
import DashboardPage from 'components/pages/DashboardPage'
import AccountPermissionsPage from 'components/pages/Account/PermissionsPage'
// import ArticlesNewPage from 'components/pages/Articles/NewPage'
// import ArticlesEditPage from 'components/pages/Articles/EditPage'
import TemplatesIndexPage from 'components/pages/Templates/IndexPage'
import TemplatesNewPage from 'components/pages/Templates/NewPage'
import TemplatesEditPage from 'components/pages/Templates/EditPage'
import TemplatesAssetsIndexPage from 'components/pages/Templates/Assets/IndexPage'
// import TemplatesAssetsUploadPage from 'components/pages/Templates/Assets/UploadPage'
// import TemplatesAssetsNewPage from 'components/pages/Templates/Assets/NewPage'
// import TemplatesAssetsEditPage from 'components/pages/Templates/Assets/EditPage'

import MainVersionsIndexPage from 'components/pages/Main/Versions/IndexPage'
import MainVersionsNewPage from 'components/pages/Main/Versions/NewPage'
import MainVersionsShowPage from 'components/pages/Main/Versions/ShowPage'
import MainVersionsAssetsEditPage from 'components/pages/Main/Versions/Assets/EditPage'
import MainVersionsDraftsEditPage from 'components/pages/Main/Versions/Drafts/EditPage'

import SharedAssetsIndexPage from 'components/pages/Shared/Assets/IndexPage'

import LabelsNewPage from 'components/pages/Labels/NewPage'
import LabelsEditPage from 'components/pages/Labels/EditPage'
import LabelsIndexPage from 'components/pages/Labels/IndexPage'
import LabelsUploadPage from 'components/pages/Labels/UploadPage'

import ComponentsEditPage from 'components/pages/Components/EditPage'
import ComponentsIndexPage from 'components/pages/Components/IndexPage'
import ComponentsNewPage from 'components/pages/Components/NewPage'

import DefsIndexPage from 'components/pages/Defs/IndexPage'
import DefsEditPage from 'components/pages/Defs/EditPage'
import DefsNewPage from 'components/pages/Defs/NewPage'

import ResourcesIndexPage from 'components/pages/Resources/IndexPage'
import ResourcesEditPage from 'components/pages/Resources/EditPage'
import ResourcesNewPage from 'components/pages/Resources/NewPage'
import ResourcesUploadPage from 'components/pages/Resources/UploadPage'

import SiteConfigEditPage from 'components/pages/SiteConfig/EditPage'
import RedirectRuleIndexPage from 'components/pages/RedirectRule/IndexPage'
import RedirectRuleNewPage from 'components/pages/RedirectRule/NewPage'
import RedirectRuleEditPage from 'components/pages/RedirectRule/EditPage'

export default (props) => {
  return (
    <SuperCacheProvider>
      <Main {...props} />
    </SuperCacheProvider>
  )
}

export const Main = ({me}) => {
  const [loading, setLoading] = useState(true)
  const {updateMe} = useSuperCache()
  useEffect(()=>{
    updateMe(me)
    setLoading(false)
  }, [me])
  const {staff, permission} = me

  if (loading) return (<CircularProgress />)
  return (
    <Router>
      <ThemeProvider>
        <FlashProvider>
        {/*<Header />*/}
          {
            permission ? 
              (<Dashboard>{PermittedRoute({me})}</Dashboard>) : 
              (<Standalone>{UnPermittedRoute({me})}</Standalone>) 
          }
        </FlashProvider>
      </ThemeProvider>
    </Router>
  );
}

const PermittedRoute = ({me}) => {
  const {staff, permission} = me
  return (
    <Switch>
      <Route path="/account/permissions" exact component={AccountPermissionsPage} />
      <Route path="/" exact component={DashboardPage} />
      {/*
      <Route path="/articles/new" exact component={ArticlesNewPage} />
      <Route path="/article/:id" exact component={ArticlesEditPage} />
      */}
      <Route path="/templates" exact component={TemplatesIndexPage} />
      <Route path="/templates/new" exact component={TemplatesNewPage} />
      <Route path="/templates/:id" exact component={TemplatesEditPage} />
      <Route path="/templates/:template_id/assets" exact component={TemplatesAssetsIndexPage} />
      {/*
      <Route path="/templates/:template_id/assets/upload" exact component={TemplatesAssetsUploadPage} />
      <Route path="/templates/:template_id/assets/new" exact component={TemplatesAssetsNewPage} />
      <Route path="/templates/:template_id/assets/:id" exact component={TemplatesAssetsEditPage} />
      */}

      <Route path="/main/versions" exact component={MainVersionsIndexPage} />
      <Route path="/main/versions/new" exact component={MainVersionsNewPage} />
      <Route path="/main/versions/:fingerprint" exact component={MainVersionsShowPage} />
      <Route path="/main/versions/:fingerprint/assets/:id" exact component={MainVersionsAssetsEditPage} />
      <Route path="/main/versions/:fingerprint/drafts/:id" exact component={MainVersionsDraftsEditPage} />

      <Route path="/shared/assets" exact component={SharedAssetsIndexPage} />
      
      <Route path="/labels/new" exact component={LabelsNewPage} />
      <Route path="/labels/new/*" exact component={LabelsNewPage} />
      <Route path="/labels/upload" exact component={LabelsUploadPage} />
      <Route path="/labels/prefix/*" exact component={LabelsIndexPage} />
      <Route path="/labels/:id" exact component={LabelsEditPage} />
      <Route path="/labels" exact component={LabelsIndexPage} />

      <Route path="/components/new" exact component={ComponentsNewPage} />
      <Route path="/components/:id" exact component={ComponentsEditPage} />
      <Route path="/components" exact component={ComponentsIndexPage} />

      <Route path="/defs/new" exact component={DefsNewPage} />
      <Route path="/defs/:id" exact component={DefsEditPage} />
      <Route path="/defs" exact component={DefsIndexPage} />

      <Route path="/defs/:def_id/resources/upload" exact component={ResourcesUploadPage} />
      <Route path="/defs/:def_id/resources/new" exact component={ResourcesNewPage} />
      <Route path="/defs/:def_id/resources/:id" exact component={ResourcesEditPage} />
      <Route path="/defs/:def_id/resources" exact component={ResourcesIndexPage} />

      <Route path="/site/site_config" exact component={SiteConfigEditPage} />
      <Route path="/site/redirect_rules" exact component={RedirectRuleIndexPage} />
      <Route path="/site/redirect_rules/new" exact component={RedirectRuleNewPage} />
      <Route path="/site/redirect_rules/:id" exact component={RedirectRuleEditPage} />

      <Route render={props=><Redirect to="/" />} />
    </Switch>
  )
}

const UnPermittedRoute = ({me}) => {
  const {staff, permission} = me
  return (
    <Switch>
      <Route path="/account/permissions" exact component={AccountPermissionsPage} />
      <Route render={props=><Redirect to="/account/permissions" />} />
    </Switch>
  )
}