import React, {useState, useEffect} from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import {Wrapper, WrapForm, PaperPadding, Section} from 'components/molecules/Wrap'
import Uploader from 'components/molecules/forms/Uploader'

import HelpIcon from '@material-ui/icons/Help'

export default (props) => {
  const def_id = props.match.params.def_id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [def, setDef] = useState()
  const [disableOverwrite, setDisableOverwrite] = useState(false)

  useEffect(()=>{
    setLoading(true)
    
    const api1 = Api.fetchAuth(`/defs/${def_id}`).then(r=>r.json())

    Promise.all([api1]).then(responses=>{
      setDef(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })

  }, [])

  const onDrop = acceptedFiles => {
    const file = acceptedFiles[0]
    const formData = new FormData()
    formData.append("file", file)
    formData.append("disable_overwrite", disableOverwrite)
    Api.fetchAuth(`/defs/${def_id}/resources/upload`, {
      method: 'POST',
      body: formData
    }).then(r=>r.json()).then(response=>{
      // console.log(response)
      window.location.href = `/defs/${def_id}/resources`
    }).catch(handleApiError)
  }

  if (loading) return <CircularProgress />

  return (
    <React.Fragment>
      <PaperPadding>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item>
            <Link href={`/defs/${def.id}`}>リソース定義を変更</Link>
          </Grid>
          <Grid item>
            <Link href={`/defs/${def.id}/resources`}>リソース一覧</Link>
          </Grid>
        </Grid>
      </PaperPadding>
      <WrapForm>
        <Typography variant="h6" gutterBottom>リソース {def.slug} アップロード</Typography>
        <Wrapper>
          <FormControlLabel
            control={
              <Switch
                checked={disableOverwrite}
                onChange={()=>setDisableOverwrite(!disableOverwrite)}
                name="disable_overwrite"
                color="primary"
              />
            }
            label="上書き禁止モード"
          /> ※新規リソース追加時はこのモードをONにすることを推奨します
          {disableOverwrite && <Typography>同一slugのリソースがすでに存在した場合、データの更新をスキップします</Typography>}
        </Wrapper>
        <Wrapper>
          <Uploader onDrop={onDrop} />
        </Wrapper>
        {renderInstruction(def)}
      </WrapForm>
    </React.Fragment>
  )
}

const renderInstruction = (def) => {
  const customFields = Object.keys(def.arguments).filter(x=>x!=='id')
  const headerFields = ['slug', 'title', 'content'].concat(customFields).concat('labels')
  const titleSamples = [
    "Loch Ness Monster may be a giant eel, say scientists",
    "Strategies of a honey bee virus",
    "Humans Have Salamander-Like Ability to Regrow Cartilage in Joints",
    "Koala epidemic provides lesson in how DNA protects itself from viruses",
  ]
  const contentSamples = [
    "The creatures behind repeated sightings of the fabled Loch Ness Monster may be giant eels, according to scientists.",
    "The Israeli Acute Paralysis Virus is a pathogen that affects honey bees and has been linked to Colony Collapse Disorder, a key factor in decimating the bee population. Researchers have now analyzed in detail how the virus hijacks the cellular protein production machinery and misuses it for its own purposes. The research, published in The EMBO Journal, is an important step towards the development of strategies to fight the Colony Collapse Disorder.",
    "Contrary to popular belief, cartilage in human joints can repair itself through a process similar to that used by creatures such as salamanders and zebrafish to regenerate limbs, researchers have found.",
    "In animals, infections are fought by the immune system. Studies on an unusual virus infecting wild koalas, by a team of researchers from the University of Massachusetts Medical School and the University of Queensland, reveal a new form of \"genome immunity.\" The study appears October 10 in the journal Cell.",
  ]
  const labelsSamples = [
    "/b/cat/pickup",
    "",
    "/b/tag/popular;/b/tag/greatesthits;/b/cat/science",
    "/english;",
  ]
  const makeRows = (i) => headerFields.map(x=>{
    if (x === 'slug') return `slug${i+1}`
    if (x === 'title') return titleSamples[i]
    if (x === 'content') return contentSamples[i]
    if (x === 'labels') return labelsSamples[i]
    return ['xxxx','aaaaa','123','abcde'][i]
  }).map(x=>`"${x}"`)
  const rows = [0,1,2,3].map(x=>makeRows(x))

  return (
    <Section icon={<HelpIcon />} title="About csv Format">
      <Wrapper>
        <Typography variant="body1">
          このテンプレートでは、 <mark>{headerFields.join(', ')}</mark> の各フィールドをcsvで記載する必要があります。<br/>
          csvにはヘッダ行（はじめの1行目）が必須です。（下記サンプル参考）<br/>
          ヘッダ行のとおりであれば順番はこのとおりでなくてもかまいません。
        </Typography>
      </Wrapper>

      <Wrapper>
        <Typography variant="body1">
          <mark>slug</mark>は省略可能です。省略した場合ランダムコードとなります。
        </Typography>
      </Wrapper>

      <Wrapper>
        <Typography variant="body1">
          labelsが複数ある場合は、<mark>セミコロン (;)</mark> 区切りで指定します。<br/>
          ※ 以下csvサンプルの "slug3" 参考
        </Typography>
      </Wrapper>

      <Wrapper>
        <Typography variant="body1">
          データにカンマ、ダブルクォーテーション、改行などを含む場合は適切なエスケープ処理を施す必要があります。
        </Typography>
      </Wrapper>

      <span>※ csvサンプル</span>
      <Paper>
        <div style={{width: '100%', overflowX: 'scroll'}}>
          <pre>{`
            ${headerFields.map(x=>`"${x}"`).join(',')}
            ${rows[0].join(',')}
            ${rows[1].join(',')}
            ${rows[2].join(',')}
            ${rows[3].join(',')}
          `}</pre>
        </div>
      </Paper>
    </Section>
  )
}
