
export const normalizePath = (value) => {
  const extracted = (value || "").split('/').filter(x=>x).join('/')
  return `/${extracted}`
}

export const currency = (value) => {
  if (typeof value !== 'number') return null
  const formatted = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return `NT$${formatted}`
}

export default {
  normalizePath,
}