import React, {useState, useEffect} from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import { WrapForm, InfoPaper, PaperPadding } from 'components/molecules/Wrap'
import ButtonUpload from 'components/atoms/ButtonUpload'
import ButtonAdd from 'components/atoms/ButtonAdd'

import Form from './parts/Form'
import Information from './parts/Information'

import useUpdater from 'modules/useUpdater'

export default (props) => {
  const id = props.match.params.id
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)
  const [subject, setSubject] = useState()
  const [lastUpdatedAt, update] = useUpdater()

  useEffect(()=>{
    setLoading(true)
    
    const api1 = Api.fetchAuth(`/defs/${id}`).then(r=>r.json())

    Promise.all([api1]).then(responses=>{
      setSubject(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })

  }, [lastUpdatedAt])

  const save = (body) => {
    // console.log('body', body)
    return Api.fetchAuth(`/defs/${id}`, {
      method: 'PUT',
      body: body
    }).then(r=>r.json()).then(response=>{
      update()
      return Promise.resolve()
    }).catch((error)=>{
      handleApiError(error)
      return Promise.resolve()
    })
  }

  if (loading) return <CircularProgress />

  return (
    <>
      <PaperPadding>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item>
            <Link href={`/defs/${id}/resources`}>{subject.resources_count}件のリソース</Link>
          </Grid>
          <Grid item>
            <ButtonAdd href={`/defs/${id}/resources/new`} />
          </Grid>
          <Grid item>
            <ButtonUpload href={`/defs/${id}/resources/upload`} />
          </Grid>
        </Grid>
      </PaperPadding>
      <WrapForm>
        <Typography variant="h6" gutterBottom>リソース定義</Typography>
        <Form {...{save, subject}} />
      </WrapForm>
    </>
  )
}
