import React, {useState, useEffect} from 'react'
import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import Api from 'utils/Api'
import useFlash from 'modules/useFlash'
import {useSuperCache} from 'modules/SuperCache'
import {WrapForm, PaperPadding} from 'components/molecules/Wrap'
import DirectoryTree from 'components/organisms/DirectoryTree'
import Information from './parts/Information'
import useUpdater from 'modules/useUpdater'

export default (props) => {
  const fingerprint = props.match.params.fingerprint
  const {handleApiError} = useFlash()
  const {me,updateMe} = useSuperCache()
  const [loading, setLoading] = useState(true)
  const [version, setVersion] = useState()
  const [editing, setEditing] = useState(false)
  const [lastUpdatedAt, update] = useUpdater()

  useEffect(()=>{
    setLoading(true)

    const subject = Api.fetchAuth(`/main/versions/${fingerprint}`).then(r=>r.json())
    Promise.all([subject]).then(responses=>{
      setVersion(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })
  }, [lastUpdatedAt])

  // const onPublish = () => {
  //   const res = window.confirm("Are you sure to publish?")
  //   if (!res) return
  //   Api.fetchAuth(`/main/versions/${version.id}/publish`, {
  //     method: 'POST'
  //   }).then(r=>r.json()).then(response=>{
  //     console.log('published fingerprint!!!', response.data)
  //     me.party.release_main_version_fingerprint = response.data
  //     updateMe(me)
  //     update()
  //   }).catch(handleApiError)
  // }

  const onCommit = () => {
    const res = window.confirm("Make a new version based on this changes, is it OK?")
    if (!res) return
    Api.fetchAuth(`/main/draft_files/default/commit`, {
      method: 'POST',
      body: {
        base_version_id: version.id,
      }
    }).then(r=>r.json()).then(response=>{
      // update()
      window.location.href = `/main/versions`
    }).catch(handleApiError)
  }

  const onClear = () => {
    const res = window.confirm("Remove all the drafts, is it OK?")
    if (!res) return
    Api.fetchAuth(`/main/draft_files/default/clear`, {
      method: 'DELETE',
      body: {
        base_version_id: version.id,
      }
    }).then(r=>r.json()).then(response=>{
      me.party.drafts = response.data
      updateMe(me)
      update()
    }).catch(handleApiError)
  }

  const handleChange = (response) => {
    console.log('change!!!',response.data)
    me.party.drafts = response.data
    updateMe(me)
    update()
  }

  if (loading) return (<CircularProgress />)

  const draftChanges = version && version.draft ? version.draft.file_changes : []
  const items = editing && version.draft_assets ?
    version.draft_assets :
    version.assets

  return (
    <>
      <PaperPadding>
        <Information version={version} />
      </PaperPadding>
      <WrapForm>
        <Grid container alignItems="flex-start" justify="flex-end" direction="row" spacing={1}>
          <Grid item>
            {draftChanges.length>0 && <Button
              onClick={onCommit}
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddCircleIcon />}>
              Commit
            </Button>}
          </Grid>
          <Grid item>
            {draftChanges.length>0 && <Button
              onClick={onClear}
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<DeleteIcon />}>
              Clear
            </Button>}
          </Grid>
          <Badge style={{paddingLeft: 8}} color="secondary" 
            badgeContent={draftChanges.length} invisible={draftChanges.length <= 0}>
            <FormControlLabel control={
              <Switch color="primary" checked={editing} onChange={()=>setEditing(!editing)} />
            } label="Edit" />
          </Badge>
        </Grid>
        <DirectoryTree
          items={items} 
          version={version} 
          editing={editing} 
          onChange={handleChange} />
      </WrapForm>
    </>
  )
}