import useFlash from 'modules/useFlash'

export default ({consumer}) => {
  const {enqueueSnackbar} = useFlash()
  const channel = consumer.subscriptions.create("NotificationChannel", {
    connected() {
      console.log("connected");
    },

    disconnected() {
      console.log("disconnected");
    },

    received(data) {
      console.log("received",  data);
      enqueueSnackbar(data.message, {variant: data.kind || 'success'})
    },

    // speak(mes = "hello") {
    //   this.perform('speak', {mes});
    // }
  })
  return {channel}
}