import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
// import SvgIcon from '@material-ui/core/SvgIcon';
// import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring';

import Api from '../../utils/Api'
import useFlash from '../../modules/useFlash'

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles(theme => ({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))(props => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles(theme=>({
  root: {
    flexGrow: 1,
  },
  endItemRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  endItemText: {
    flexGrow: 1,
  }
}));

export default ({items, version, editing, onChange}) => {
  const classes = useStyles()
  // const entityIcon = editing ? (
  //   <DeleteFileForm onPerform={onChange} />
  // ) : null
  const nestedItems = getNestedItems(items)
  console.log('nestedItems', nestedItems)

  const renderTreeItemRecursive = (nestedItems, paths) => {
    return Object.keys(nestedItems).map((itemKey,i)=>{
      const key = `${itemKey}-${i}`
      const item = nestedItems[itemKey]
      const newPaths = paths.concat([itemKey])
      const link = item.kind === 'draft' ?
        `/main/versions/${version.fingerprint}/drafts/${item.id}` :
        `/main/versions/${version.fingerprint}/assets/${item.id}`
      if (!item.id) {
        return (
          <StyledTreeItem key={key} nodeId={key} 
            label={itemKey}>
            {renderTreeItemRecursive(item, newPaths)}
            {
              editing ?
                (<CreateFileForm paths={newPaths} version={version} onPerform={onChange} />) :
                (<React.Fragment></React.Fragment>)
            }
          </StyledTreeItem>
        )
      } else {
        return (
          <StyledTreeItem key={key} nodeId={key} 
            icon={editing && <DeleteFileForm onPerform={onChange} path={item.path} version={version} />}
            label={
              <div className={classes.endItemRoot}>
                <Typography className={classes.endItemText}>
                  <Link href={link}>{itemKey}</Link>
                </Typography>
                <Typography>
                  {item.content_type}
                </Typography>
              </div>
            } />
        )
      }
    })
  }

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['1']}
      defaultCollapseIcon={<Icon color="primary">folder</Icon>}
      defaultExpandIcon={<Icon color="primary">folder_open</Icon>}
      defaultEndIcon={<Icon color="disabled">description_outlined</Icon>}
    >
      {renderTreeItemRecursive(nestedItems, [])}
      {
        editing ?
          (<CreateFileForm paths={[]} version={version} onPerform={onChange} />) :
          (<React.Fragment></React.Fragment>)
      }
    </TreeView>
  );
}

const getNestedItems = (items) => {
  let base = {}
  items.reduce((dummy,item)=>{
    const paths = item.path.split('/').filter(x=>x)
    const lastPath = paths.pop()
    let accResult = paths.reduce((acc,i)=>{
      acc = acc[i] = acc[i] || {}
      return acc
    }, base)
    if( lastPath ) accResult = accResult[lastPath] = item
    return accResult
  }, null)
  return base
}

const DeleteButton = ({onClick}) => (
  <IconButton size="small" onClick={onClick}><Icon color="error">delete</Icon></IconButton>
)
const CreateButton = ({onClick}) => (
  <IconButton size="small" onClick={onClick}><Icon color="primary">add_circle</Icon></IconButton>
)

const DeleteFileForm = ({path, version, onPerform}) => {
  const {handleApiError} = useFlash()
  const onClick = () => {
    Api.fetchAuth(`/main/draft_files/default`, {
      method: 'DELETE',
      body: {
        path: path,
        base_version_id: version.id,
      }
    }).then(r=>r.json()).then(res=>onPerform(res)).catch(handleApiError)
  }

  return (
    <DeleteButton onClick={onClick} />
  )
}

const CreateFileForm = ({paths, version, onPerform}) => {
  const {handleApiError} = useFlash()
  const [filename, setFilename] = useState("")
  const path = paths.concat([filename]).join('/')
  const onClick = () => {
    if (!filename) {
      alert("Please input file name")
      return
    }

    Api.fetchAuth(`/main/draft_files/default`, {
      method: 'PUT',
      body: {
        path: path,
        body: '',
        base_version_id: version.id,
      }
    }).then(r=>r.json()).then(res=>onPerform(res)).catch(handleApiError)
  }
  
  return (
    <StyledTreeItem nodeId={`new-${paths.join('-')}`} 
      icon={<CreateButton onClick={onClick} />} 
      label={
        <TextField value={filename} margin="dense"
          onKeyDown={e=>e.stopPropagation()}
          onChange={e=>setFilename(e.target.value)}
          placeholder={`sample.html`} />
      } />
  )
}
