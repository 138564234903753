import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';

import Icon from '@material-ui/core/Icon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AdjustIcon from '@material-ui/icons/Adjust';
import {useSuperCache} from '../../modules/SuperCache'

import newSideMenuItem from 'components/molecules/newSideMenuItem'

export const PrimaryListItems = () => {
  const mainItem = newSideMenuItem({
    label: "メイン",
    default: !! window.location.pathname.match(/\/main/),
    icon: "layers",
    items: [
      {
        title: "List",
        href: "/main/versions",
        icon: "list",
      },
    ]
  })
  const resourceItem = newSideMenuItem({
    label: "リソース",
    default: !! (window.location.pathname.match(/\/resources/) || window.location.pathname.match(/\/defs/)),
    icon: "description",
    items: [
      {
        title: "List",
        href: "/defs",
        icon: "list",
      },
      {
        title: "New",
        href: "/defs/new",
        icon: "add",
      },
    ]
  })
  const templateItem = newSideMenuItem({
    label: "テンプレート",
    default: !! window.location.pathname.match(/\/templates/),
    icon: "description",
    items: [
      {
        title: "List",
        href: "/templates",
        icon: "list",
      },
      {
        title: "New",
        href: "/templates/new",
        icon: "add",
      },
    ]
  })
  const labelItem = newSideMenuItem({
    label: "ラベル",
    default: !! window.location.pathname.match(/\/labels/),
    icon: "local_offer",
    items: [
      {
        title: "List",
        href: "/labels",
        icon: "list",
      },
      {
        title: "New",
        href: "/labels/new",
        icon: "add",
      },
    ]
  })
  const componentItem = newSideMenuItem({
    label: "コンポーネント",
    default: !! window.location.pathname.match(/\/components/),
    icon: "speed",
    items: [
      {
        title: "List",
        href: "/components",
        icon: "list",
      },
      {
        title: "New",
        href: "/components/new",
        icon: "add",
      },
    ]
  })
  const siteItem = newSideMenuItem({
    label: "サイト設定",
    default: !! window.location.pathname.match(/\/site/),
    icon: "settings",
    items: [
      {
        title: "カスタムHTML",
        href: "/site/site_config",
        icon: "list",
      },
      {
        title: "リダイレクト",
        href: "/site/redirect_rules",
        icon: "list",
      },
    ]
  })

  return (
    <div>
      <ListItem button component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      {mainItem.render}

      <ListItem button component="a" href="/shared/assets">
        <ListItemIcon>
          <AttachFileIcon />
        </ListItemIcon>
        <ListItemText primary="Shared" />
      </ListItem>

      {resourceItem.render}
      {templateItem.render}
      {labelItem.render}
      {componentItem.render}
      {siteItem.render}

      {/*<ListItem button component="a" href="/site_config">
        <ListItemIcon>
          <Icon>settings</Icon>
        </ListItemIcon>
        <ListItemText primary="サイト設定" />
      </ListItem>*/}
    </div>
  )
}

export const SecondaryListItems = () => {
  const {me} = useSuperCache()
  const {party: {release_templates}} = me

  return (
    <div>
      <ListSubheader inset>Shortcut</ListSubheader>
        <ListItem button component="a" href='/docs/ja/overview.html'>
          <ListItemIcon>
            <Icon>book</Icon>
          </ListItemIcon>
          <ListItemText primary="Document" />
        </ListItem>
      {
        release_templates && release_templates.map((template)=>{
          return (
            <ListItem button component="a" href={`/templates/${template.id}`} key={template.id}>
              <ListItemIcon>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText primary={template.title} />
            </ListItem>
          )
        })
      }
    </div>
  )
}
