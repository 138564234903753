import React from 'react'
import {useSuperCache} from 'modules/SuperCache'

export default () => {
  const {me} = useSuperCache()
  const {party} = me || {}
  const {name, release_domains, use_https} = party || {}
  const release_domain = release_domains && release_domains[0]
  return {
    site_name: name,
    site_origin: release_domain ? `${use_https ? 'https' : 'http'}://${release_domains}` : null,
  }
}