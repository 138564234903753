import React, {useState} from 'react'
// import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import HelpIcon from '@material-ui/icons/Help'

import Api from '../../../../utils/Api'
import useFlash from '../../../../modules/useFlash'
import {Wrapper, WrapForm, TextPaper, Section} from '../../../molecules/Wrap'

import Uploader from '../../../molecules/forms/Uploader'

export default () => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(false)

  const onDrop = acceptedFiles => {
    // TODO: Validates uploaded file, zip, size, ...

    setLoading(true)
    const file = acceptedFiles[0]
    const formData = new FormData()
    formData.append("file", file)
    Api.fetchAuth(`/main/versions`, {
      method: 'POST',
      body: formData
    }).then(r=>r.json()).then(response=>{
      setLoading(false)
      window.location.href = '/main/versions'
      // console.log(response)
    }).catch(handleApiError)
  }

  if (loading) return (<CircularProgress />)

  return (
    <WrapForm>
      <Typography variant="h6" gutterBottom>
        Upload Main Version
      </Typography>
      <Wrapper>
        <Uploader onDrop={onDrop} />
      </Wrapper>

      <Wrapper>
        <TextPaper>
          <Section icon={<HelpIcon />} title="About zip Format">
            <Wrapper>
              <Typography variant="body1">
                メインコンテンツとなるhtmlなどのウェブサイトアセットをzipパッケージにしてアップロードします。<br/>
                アップロードできる最大サイズは<strong>500MB</strong>です。<br/>
                500MBをこえるサイトをあつかう場合は、シェアードアセット（Shared Asset）などを利用して画像や動画をメインアセット(Main Asset)と別ラインで管理することを検討してください。
              </Typography>
            </Wrapper>

            <Wrapper>
              <Typography variant="body1">
                zipファイルは展開した状態でルートフォルダを１つはさんでコンテンツが含まれている必要があります。<br/>
                Mac OSでは以下のようにzip作成できます。
              </Typography>
            </Wrapper>

            <Wrapper>
              <img style={{maxWidth: '100%', width: 'auto'}} src="/docs/images/zipping-example.png" alt='zipping-example' />
            </Wrapper>

            <Wrapper>
              exampleフォルダにたくさんのコンテンツが入っています。<br/>
              exampleフォルダを右クリックして、「圧縮」メニューを選択することで、このフォルダ全体を圧縮したzipパッケージを作成できます。<br/>
              この場合、<mark>http://あなたのドメイン/</mark>にアクセスしたとき、赤線で記した<mark>index.html</mark>の内容が表示されることになります。
            </Wrapper>

            <Wrapper>
              作成したzipパッケージが想定どおりのフォルダ構成になっているかどうかは、ターミナル(Mac)をつかって確認できます。<br/>
              <mark>unzip -l <strong><i>zipファイルパス</i></strong></mark><br/>
              このコマンドをターミナルのプロンプトに打ち込むと、zipに格納されるファイルを一覧できます。
            </Wrapper>

            <Wrapper>
              <img style={{maxWidth: '100%', width: 'auto'}} src="/docs/images/unzipping-example.png" alt='unzipping-example' />
            </Wrapper>

            <Wrapper>
              zip内のファイルを一覧したとき、ファイル名がルートフォルダを１つはさんでいる必要があります。<br/>
              <b>OK</b>の例では、<mark>example/</mark>がルートフォルダになっています。フォルダ名はexampleでなくとも、なんでもかまいません。このフォルダ名はURLからアクセスする際には表示されません。<br/>
              <mark>http://あなたのドメイン/</mark>にアクセスしたとき、赤線で記した<mark>example/index.html</mark>が表示されることになります。
              同様に、<mark>http://あなたのドメイン/recruit</mark>にアクセスしたとき、<mark>example/recruit/index.html</mark>が配信されます。
            </Wrapper>

            <Wrapper>
              <img style={{maxWidth: '100%', width: 'auto'}} src="/docs/images/unzipping-badexample.png" alt='unzipping-badexample' />
            </Wrapper>

            <Wrapper>
              <b>NG</b>の例では、ルートフォルダが存在せず、ファイルが直接zipに含まれてしまっています。この場合、基本的にシステムはエラーを通知してアップロードを拒否します。<br/>
              zip作成方法をもう一度おたしかめください。
            </Wrapper>
          </Section>

        </TextPaper>
      </Wrapper>
    </WrapForm>
  )
}