import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
// import Checkbox from '@material-ui/core/Checkbox';
// import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import useFlash from '../../../modules/useFlash'
import Api, {handleError} from '../../../utils/Api'
import AuthToken from '../../../utils/AuthToken'
import PermissionChip from '../../molecules/PermissionChip'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

export default () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState([])
  const {handleApiError} = useFlash()
  useEffect(()=>{
    setLoading(true)
    Api.fetchAuth('/permissions').then(r=>r.json()).then(res=>{
      setPermissions(res.data)
      setLoading(false)
    }).catch(error=>{
      console.log('abc err',error)
      setLoading(false)
      handleApiError(error)
    })
  }, [])
  const selectAccount = (permissionId) => {
    console.log('permki', permissionId)
    Api.fetchAuth('/authorize', {
      method: "POST",
      body: {permission_id: permissionId}
    }).then(r=>r.json()).then(response=>{
      const token = response.data
      AuthToken.setToken( token )
      window.location.href = '/'
    })
  }
  if (loading) return <CircularProgress />
  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Select Account
      </Typography>
      <Box mt={2}>
        <Container component="main" maxWidth="xs">
          <List className={classes.root}>
            {permissions.map(permission => {
              return (
                <ListItem key={permission.id} role={undefined} dense button 
                  onClick={()=>selectAccount(permission.id)}>
                  <ListItemIcon><PermissionChip permission={permission} /></ListItemIcon>
                  <ListItemText id={permission.id} primary={permission.party_name} />
                  {/*<ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                      <CommentIcon />
                    </IconButton>
                  </ListItemSecondaryAction>*/}
                </ListItem>
              );
            })}
          </List>
        </Container>
      </Box>
    </div>
  )
}
