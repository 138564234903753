import React, {useMemo} from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
import newTextField from 'components/molecules/forms/newTextField'
import newSelect from 'components/molecules/forms/newSelect'
import HelpTip from 'components/atoms/HelpTip'

export default ({save, subject}) => {
  subject = subject || {}

  const condition_kind = newSelect({
    required: true,
    defaultValue: subject.condition_kind, label: "ルール種別", 
    options: {
      "path_exact": "パス完全一致",
    }
  })
  const path_exact_value = newTextField({
    required: true,
    defaultValue: subject.condition ? subject.condition.value : null, 
    label: "パスが次の値に完全一致したとき", fullWidth: true,
    placeholder: '/ スラッシュから記述します'
  })
  const redirect_to = newTextField({
    required: true,
    defaultValue: subject.redirect_to, label: "リダイレクト先", fullWidth: true
  })
  const status_code = newSelect({
    required: true,
    defaultValue: subject.status_code ? subject.status_code.toString() : null, label: "ステータスコード", 
    options: {
      "301": "301",
      "302": "302",
    }
  })

  const condition = useMemo(()=>{
    if (condition_kind.value === 'path_exact' && path_exact_value.value) {
      return {value: path_exact_value.value}
    } else {
      return null
    }
  }, [condition_kind.value, path_exact_value.value])
  
  const body = {
    condition_kind: condition_kind.value,
    condition: condition,
    redirect_to: redirect_to.value,
    status_code: status_code.value,
  }

  return (
    <React.Fragment>
      {/*<p>{JSON.stringify(condition)}</p>*/}
      <GeneratedForm handleSave={()=>save(body)} objects={[
        condition_kind,
        condition_kind.value === 'path_exact' && path_exact_value,
        redirect_to,
        (
          <HelpTip>
            外部サイトへのリダイレクトはhttp://またはhttps://から記述します。<br/>
            サイト内リダイレクトは/から記述します。
          </HelpTip>
        ),
        status_code
      ]} />
    </React.Fragment>
  )
}