import React, {useState,useMemo,useCallback,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import TableCell from '@material-ui/core/TableCell'
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import newSelectableList from './newSelectableList'
// import AssetList from 'components/molecules/AssetList'
// import AssetCard from 'components/molecules/AssetCard'
import newSearchBar from 'components/molecules/forms/newSearchBar'
import {ItemListTemplate} from 'components/organisms/newRowsPager'

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: theme.spacing(1),
  },
  gridList: {
    // maxHeight: 450,
    marginBottom: theme.spacing(2),
  },
  selector: {
    backgroundColor: theme.palette.primary.light,
  },
  // assetCard: {
  //   opacity: 0.7,
  // },
  searchItem: {
    cursor: 'pointer',
  },
}))

export const List = (props) => {
  const {values, pushValue} = props
  const [loading, setLoading] = useState(false)
  const [labels, setLabels] = useState([])
  const baseQuery = props.baseQuery || {}
  const searchBar = newSearchBar({placeholder: "Starts with /"})

  const {handleApiError} = useFlash()
  const classes = useStyles()

  useEffect(()=>{
    if (!searchBar.value) return
    setLoading(true)
    const query = {...baseQuery, prefix: searchBar.value}
    Api.fetchAuth('/labels', {query}).then(r=>r.json()).then(res=>{
      setLabels(res.data)
      setLoading(false)
    }).catch(error=>{
      // console.log('abc err',error)
      setLoading(false)
      handleApiError(error)
    })
  }, [searchBar.value])

  const unSelectedLabels = labels.filter(x=>!values.map(a=>a.id).includes(x.id))

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {searchBar.render}
      </Grid>
      <Grid item xs={12}>
        {loading && <CircularProgress size={24} />}
        <ItemListTemplate headers={["", "name", "path", ""]} rows={unSelectedLabels} 
          rowComponent={({row})=>(
            <React.Fragment>
              <TableCell>
                <IconButton size="small" color="primary" onClick={()=>{pushValue(row)}}><AddCircleIcon /></IconButton>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{row.name}</Typography>
              </TableCell>
              <TableCell>
                <Chip size="small" label={row.path} />
              </TableCell>
              <TableCell></TableCell>
            </React.Fragment>
          )} />
      </Grid>
    </Grid>
  )
}

export const Selector = (props) => {
  const classes = useStyles()
  const {values, removeValues} = props
  return (
    <React.Fragment>
      {/*<InputLabel>Selector</InputLabel>*/}
      <Grid container className={classes.selector}>
        <ItemListTemplate rows={values} 
          rowComponent={({row})=>(
            <React.Fragment>
              <TableCell>
                <IconButton size="small" color="secondary" 
                  onClick={()=>removeValues(x=>x.id === row.id)}>
                  <CancelIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{row.name}</Typography>
              </TableCell>
              <TableCell>
                <Chip size="small" label={row.path} />
              </TableCell>
              <TableCell>Selected</TableCell>
            </React.Fragment>
          )} />
      </Grid>
    </React.Fragment>
  )
}

export default (props) => {
  const {max, baseQuery} = props
  const accessors = newSelectableList({max, default: props.default})
  const renderList = List({...accessors, baseQuery})
  const renderSelector = Selector(accessors)
  const classes = useStyles()
  return {
    ...accessors,
    renderList,
    renderSelector,
    render: (
      <React.Fragment>
        {props.label && <InputLabel className={classes.label}>{props.label}</InputLabel>}
        {renderList}
        {renderSelector}
      </React.Fragment>
    )
  }
}