import React, {useState, useEffect} from 'react'
// import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import Link from '@material-ui/core/Link'

import Api, {buildLimitOffsetQuery} from '../../../../utils/Api'
import useFlash from '../../../../modules/useFlash'

import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {useSuperCache} from '../../../../modules/SuperCache'
import useUpdater from '../../../../modules/useUpdater'

import {
  parseISO,
  formatDistance, 
  // format, 
  // formatRelative, 
  // subDays 
} from 'date-fns'

import {Section} from '../../../molecules/Wrap'
// import Helper from '../../../atoms/Helper'
import Upload from './parts/Upload'

export default (props) => {
  const {handleApiError} = useFlash()
  const [lastUpdatedAt, update] = useUpdater()
  const [loading, setLoading] = useState(true)
  const [versions, setVersions] = useState()
  const query = buildLimitOffsetQuery({location_search: props.location.search})

  const {me} = useSuperCache()
  const {party: {release_main_version_fingerprint}} = me

  useEffect(()=>{
    setLoading(true)

    const getVersions = Api.fetchAuth(`/main/versions`, {query}).then(r=>r.json())

    Promise.all([getVersions]).then(responses=>{
      setVersions(responses[0].data)
      setLoading(false)
    }).catch(error=>{
      // setLoading(false)
      handleApiError(error)
    })
  }, [lastUpdatedAt])

  const onDropped = response => {
    update()
  }

  if (loading) return (<CircularProgress />)

  return (
    <React.Fragment>
      <Section title={
        <Grid container alignItems="center">
          <Grid item>Upload Main Version</Grid>
          <Grid item>
            <IconButton component="a" href={`/main/versions/new`}>
              <HelpIcon />
            </IconButton>
          </Grid>
        </Grid>
      }>
        <Upload onDropped={onDropped} />
      </Section>
      
      <Section title="List of Versions">
        <ItemList rows={versions} releaseFingerprint={release_main_version_fingerprint} />
      </Section>
    </React.Fragment>
  )
}

const ItemList = ({rows, releaseFingerprint}) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Fingerprint</TableCell>
        <TableCell>Base</TableCell>
        <TableCell>Message</TableCell>
        <TableCell>Created</TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.map(row => (
        <TableRow key={row.id}>
          <TableCell>
            {row.fingerprint === releaseFingerprint && <Chip size="small" color="secondary" label="published" />}
            {row.is_editing && <Chip size="small" color="secondary" icon={<EditIcon />} label="editing" />}
          </TableCell>
          <TableCell>
            <Link href={`/main/versions/${row.fingerprint}`}>
              {row.fingerprint.substr(0,10)}
            </Link>
          </TableCell>
          <TableCell>
            {row.base_fingerprint && <Link href={`/main/versions/${row.base_fingerprint}`}>
              {row.base_fingerprint.substr(0,10)}
            </Link>}
          </TableCell>
          <TableCell>{row.message}</TableCell>
          <TableCell>{
            formatDistance(parseISO(row.created_at), new Date())
          }</TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
)
