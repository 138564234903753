import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Api, { handleError } from 'utils/Api'
import useFlash from 'modules/useFlash'

import { Wrapper, WrapForm } from 'components/molecules/Wrap'
import newRowsPager from 'components/organisms/newRowsPager'
import ResourceStatusButton from 'components/atoms/ResourceStatusButton'

export default (props) => {
  const {handleApiError} = useFlash()
  const [loading, setLoading] = useState(true)

  const itemPager = newRowsPager({
    headers: ['slug', 'タイトル', 'status',],
    onPage: ({offset, limit, updateRows}) => {
      // console.log('offlimit', offset, limit)
      const params = {
        query: {offset, limit}
      }
      setLoading(true)
      Api.fetchAuth(`/templates`, params).then(r=>r.json()).then(response=>{
        updateRows(response.data, response.total)
        setLoading(false)
      }).catch(handleApiError)
    },
    rowComponent: ({row, updateRowById}) => (
      <React.Fragment>
        <ListItem {...row} />
        <TableCell>
          <ResourceStatusButton 
            resource_key="defs" 
            resource={row} 
            updateRowById={updateRowById} />
        </TableCell>
      </React.Fragment>
    )
  })
  
  return (
    <Wrapper>
      <Typography variant="h6" gutterBottom>
        <Grid container direction="row" justify="space-between">
          <Grid item>テンプレート一覧</Grid>
          <Grid item>
            <Button href={`/templates/new`} variant="contained" color="default"
              size="small" startIcon={<AddCircleIcon />}>
              New
            </Button>
          </Grid>
        </Grid>
      </Typography>
      
      {loading && <CircularProgress size={20} />}
      {itemPager.render}
    </Wrapper>
  )
}

const ListItem = (row) => (
  <React.Fragment>
    <TableCell>
      <Link href={`/templates/${row.id}`} color="inherit">
        <Typography variant="body2">{row.slug}</Typography>
      </Link>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{row.title}</Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body2">{row.status}</Typography>
    </TableCell>
  </React.Fragment>
)
