import React from 'react'
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export default (props) => {
  return (
    <Button
      variant="contained"
      color="default"
      size="small"
      {...props}
      startIcon={<CloudUploadIcon />}>
      Upload
    </Button>
  )
}