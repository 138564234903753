import React from 'react'
import GeneratedForm from 'components/organisms/GeneratedForm'
// import Box from '@material-ui/core/Box';
// import InputLabel from '@material-ui/core/InputLabel';
// import newTextField from 'components/molecules/forms/newTextField'
// import newKeyValueFields from 'components/molecules/forms/newKeyValueFields'
// import newNestedKeyValueFields from 'components/molecules/forms/newNestedKeyValueFields'
// import newCodeEditor from 'components/molecules/forms/newCodeEditor'
import newHtmlEditor from 'components/molecules/forms/newHtmlEditor'

// import HelpTip from 'components/atoms/HelpTip'

export default ({labelMaster, save, subject}) => {
  subject = subject || {}

  const html_insert_top_head = newHtmlEditor({
    label: "html insert top head",
    defaultValue: subject.html_insert_top_head,
    style: {
      fontSize: '1rem',
      width: 400, heigh: 200,
    }
  })
  const html_insert_bottom_head = newHtmlEditor({
    label: "html insert bottom head",
    defaultValue: subject.html_insert_bottom_head,
    style: {
      fontSize: '1rem',
      width: 400,
    }
  })
  const html_insert_top_body = newHtmlEditor({
    label: "html insert top body",
    defaultValue: subject.html_insert_top_body,
    style: {
      fontSize: '1rem',
      width: 400,
    }
  })
  const html_insert_bottom_body = newHtmlEditor({
    label: "html insert bottom body",
    defaultValue: subject.html_insert_bottom_body,
    style: {
      fontSize: '1rem',
      width: 400,
    }
  })
  const body = {
    html_insert_top_head: html_insert_top_head.html,
    html_insert_bottom_head: html_insert_bottom_head.html,
    html_insert_top_body: html_insert_top_body.html,
    html_insert_bottom_body: html_insert_bottom_body.html,
  }

  return (
    <React.Fragment>
      <GeneratedForm handleSave={()=>save(body)} objects={[
        html_insert_top_head,
        html_insert_bottom_head,
        html_insert_top_body,
        html_insert_bottom_body,
      ]} />
    </React.Fragment>
  )
}