import React, {useState} from 'react'
import Box from '@material-ui/core/Box';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

import newRichEditor from 'components/molecules/forms/newRichEditor'
import newCodeEditor from 'components/molecules/forms/newCodeEditor'

import {
  ContentState,
  // convertToRaw,convertFromRaw,convertFromHTML
} from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

export default ({defaultValue, defaultMode, label}) => {
  const [mode, setMode] = useState(defaultMode || 'rte')
  const codeEditor = newCodeEditor({defaultValue: defaultValue})
  const richEditor = newRichEditor({defaultValue: defaultValue})
  const toggleHtmlMode = () => {
    codeEditor.setValue(richEditor.html)
    setMode('html')
  }
  const toggleRTEMode = () => {
    // const contentBlock = convertFromHTML(codeEditor.value)
    const contentBlock = htmlToDraft(codeEditor.value || '')
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap)
    richEditor.setContentState(contentState)
    setMode('rte')
  }
  const toggleMode = () => {
    mode === 'html' ? toggleRTEMode() : toggleHtmlMode()
  }

  const render = (
    <React.Fragment>
      <FormControlLabel
        control={
          <Switch
            disabled={false}
            checked={mode === 'html'}
            onChange={toggleMode}
            color="primary"
          />
        }
        label="HTML"
      />
      <FormHelperText>※HTMLモードからリッチテキストモードに切り替えると、失われる要素があるのでご注意ください</FormHelperText>
      <Box display={mode === 'rte' ? 'block' : 'none'}>{richEditor.render}</Box>
      <Box display={mode === 'html' ? 'block' : 'none'}>{codeEditor.render}</Box>
    </React.Fragment>
  )

  return {
    render, mode,
    html: mode === 'html' ? codeEditor.value : richEditor.html
  }
}
