
const envs = {
  development: {
    api_prefix: process.env.REACT_APP_API_PREFIX || "http://localhost:3000/admin_api/v1",
    api_logging: true,
    ws_endpoint: process.env.REACT_APP_WS_ENDPOINT || "ws://localhost:3000/admin_cable",
  },
  staging: {
    api_prefix: "https://staging.puffin.reivo.info/admin_api/v1",
    api_logging: true,
    ws_endpoint: "wss://staging.puffin.reivo.info/admin_cable",
  },
  production: {
    api_prefix: "https://puffin.reivo.info/admin_api/v1",
    api_logging: false,
    ws_endpoint: "wss://puffin.reivo.info/admin_cable",
  },
}

const env = process.env.REACT_APP_RELEASE_ENV ?
  envs[process.env.REACT_APP_RELEASE_ENV] :
  envs['development']

export default env