import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import AttachFileIcon from '@material-ui/icons/AttachFile'

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  cardMedia: {
    paddingTop: '100%',
  },
  cardContent: {
    flexGrow: 1,
  },

  defaultFile: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    backgroundColor: theme.palette.grey[10],
    textAlign: 'center',
  },
}));

export default ({item, onClick}) => {
  const classes = useStyles()
  const renderVisual = () => {
    if (item.rough_content_type === 'image') {
      return (
        <CardMedia className={classes.cardMedia}
          image={item.publish_url || item.s3_url} />
      )
    }
    return (<DefaultFile className={classes.cardMedia} item={item} />)
  }
  return (
    <Card className={classes.card} onClick={()=>onClick(item)}>
      {renderVisual()}
      <CardActions>
        <Typography variant="body2">{item.content_type}</Typography>
      </CardActions>
    </Card>
  )
}

const DefaultFile = ({item}) => {
  const classes = useStyles()
  return (
    <div className={classes.defaultFile}>
      <AttachFileIcon />
      <span>{item.title}</span>
    </div>
  )
}