import React from 'react'
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export default (props) => {
  return (
    <Button
      variant="contained"
      color="default"
      size="small"
      {...props}
      startIcon={<AddCircleIcon />}>
      New
    </Button>
  )
}