import React from 'react'
import Button from '@material-ui/core/Button'
import {handleError} from '../utils/Api'
import { SnackbarProvider, useSnackbar } from 'notistack'

export default () => {
  const { enqueueSnackbar } = useSnackbar()
  const enqueuePermanentError = (message)=>enqueueSnackbar(message, {variant: 'error', persist: true})
  const handleApiError = (error) => {
    return handleError({messenger: enqueuePermanentError})(error)
  }
  return {
    enqueueSnackbar, enqueuePermanentError, handleApiError
  }
}

export const FlashProvider = ({children}) => {
  const notistackRef = React.createRef()
  const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key)
  }
  return (
    <SnackbarProvider 
      maxSnack={3}
      ref={notistackRef}
      action={(key)=>(
        <Button onClick={onClickDismiss(key)}>Dismiss</Button>
      )}>
      {children}
    </SnackbarProvider>
  )
}